.actions {
  margin-top: 3.2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  gap: 1.6rem;

  > p:last-of-type, > div:last-of-type {
    margin-right: auto;
  }
}
