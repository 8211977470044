.upload {
  margin-bottom: 3.2rem;
}

.autosave {
  line-height: 1.375;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;

  .button {
    width: 100%;
  }
}
