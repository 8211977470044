@import '../../../../static/styles/variables.scss';

.px-sidebar {
  height: 100%;
  user-select: none;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  > *:last-child {
    margin-top: auto;
  }
}

.px-sidebar {
  transition: transform 0.2s;
  width: $px-sidebar-width;
  position: relative;
  margin: 0 auto;
  padding-bottom: 10px;
  height: calc(100% - 12px);
  user-select: none;
  background: rgba(255, 255, 255, 0.9) !important;
  box-shadow: 0 3px 6px #00000029;
  backdrop-filter: blur(36px);
  -webkit-backdrop-filter: blur(36px);
  border-radius: 15px 0 0 15px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &.collapsed {
    border: none;
    transform: translateX($px-sidebar-width);
  }

  .sidebar-button {
    &.notifications-icon {
      .content {
        .logo {
          background: white;
          border-radius: 50%;
        }
      }
    }

    &:hover:not(.disabled):not(.selected) {
      opacity: 0.5;

      .content {
        .logo {
          background: white;
          border-radius: 50%;

          .svg-logo {
            path {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    &.online {
      .content {
        color: $gray-11 !important;

        .logo {
          background: $red-7 !important;
          border-radius: 50%;
        }
      }
    }

    &.selected {
      .content {
        .logo {
          background: $sapphire;
          border-radius: 50%;

          .svg-logo {
            path {
              fill: white;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    &.disabled {
      .content {
        color: $gray-10;

        .logo {
          .svg-logo {
            path {
              fill: $gray-10;
            }
          }
        }
      }
    }

    .content {
      color: black;
      font-size: 12px;
      font-weight: 600;
      padding: 0 5px;

      .logo {
        display: flex;
        width: 40px;
        min-width: 40px;
        height: 40px;
        min-height: 40px;
        margin-bottom: 5px;
        align-items: center;

        .svg-logo {
          padding: 8px 10px;
          width: 100%;
          height: 100%;
          fill: #5d0054;
        }
      }
    }
  }
}