@import '@pixelcanvas/ui/dist/variables.scss';

.link.link {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  &:hover {
    text-decoration: underline solid $medium-emphasis;
  }
  > * + * {
    margin-left: 0.8rem;
  }
  svg path {
    fill: $medium-emphasis;
  }
  .text {
    color: $medium-emphasis;
    font-weight: 600;
  }
}

