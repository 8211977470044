@import '../../static/styles/variables';

.landing-bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: -2;
  background-color: #261342;
  background-size: cover;
  -webkit-background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.landing-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  user-select: none;
  color: white;
  font-family: "Roboto", Arial, Helvetica, sans-serif;

  .landing-title {
    display: flex;
    flex-direction: column;
    margin: 5px auto;
    font-size: 38px;
    font-weight: 400;
    text-align: center;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
  }

  .instructions {
    margin: 16px auto 0;
    font-size: 18px;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
  }

  .client-suggestion {
    margin: 0 auto 5px auto;
    height: 105px;
    text-align: center;
    font-size: 90px;
    font-weight: 200;
  }

  .landing-description {
    margin: 0 auto;
    text-align: center;
  }

  .get-started {
    margin: 30px auto;
    background: linear-gradient(100deg, #CC28D3 0%, #9300D9 100%) 0% 0% no-repeat padding-box;
    font-size: 18px;
    font-weight: 600;
    border-radius: 30px;
    padding: 10px 50px;
    cursor: pointer;
  }

  .hero-image {
    width: 80%;
  }
}

.header {
  position: relative;
  display: flex;
  height: 64px;
  padding: 15px 0;
  color: white;
  align-items: center;
  user-select: none;
  .topGradient {
    position: absolute;
    background: transparent linear-gradient(360deg, #66177100 0%, #60158E 100%) 0% 0% no-repeat padding-box;
    top: 0;
    width: 100%;
    height: 165px;
    z-index: -1;
    opacity: 0.47;
  }
  .centerGradient {
    position: absolute;
    background: transparent radial-gradient(closest-side at 50% 50%, #1A123D 0%, #1A123D98 55%, #1A123D00 100%) 0% 0% no-repeat padding-box;
    top: 0;
    width: 100%;
    height: 768px;
    z-index: -1;
    opacity: 0.63;
  }
  .brandLogo {
    margin-left: 20px;
    width: 65px;
    height: 65px;
    object-fit: contain;
  }
  .brandLogoText {
    margin-left: 20px;
    height: 48px;
    object-fit: contain;
  }
  .contactButton {
    color: white;
    font-weight: 600;
    margin-left: auto;
    cursor: pointer;
  }
  .headerUser {
    position: relative;
    display: flex;
    margin: 0 40px 0 auto;
    align-items: center;
    .helpButton {
      color: white;
      font-weight: 600;
      margin-left: auto;
      cursor: pointer;
      text-decoration: none;
    }
    .contactUs {
      color: white;
      font-weight: 600;
      margin: 0 40px;
      cursor: pointer;
    }
  }
}
