@import '../../static/styles/variables';

.carousel {
  display: flex;
  position: relative;
  width: fit-content;
  margin: 0 auto 0 auto;
  padding: 20px 80px;
  user-select: none;

  .carousel-backdrop {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    align-items: center;
    background: white;
    border-radius: 20px;

    .arrow {
      position: absolute;
      width: 40px;
      height: 40px;
      cursor: pointer;

      &.left {
        left: 20px;
      }

      &.right {
        right: 20px;
      }
    }
  }

  .title {
    font-size: 30px;
    font-weight: 500;
  }

  .items {
    display: flex;
    margin-top: 30px;
    z-index: 1;

    .carousel-item {
      width: 200px;
      height: fit-content;
      margin: 0 20px;
      display: flex;
      flex-direction: column;
      border-radius: 20px;
      overflow: hidden;
      user-select: all;

      .thumbnail {
        width: 100%;
        height: 200px;
        object-fit: cover;
        user-select: none;
        cursor: pointer;
      }

      .content-info {
        padding: 15px;
        background: white;
        width: 180px;

        .content-title {
          font-size: 16px;
          font-weight: bold;
        }

        .content-description {
          margin-top: 10px;
          font-size: 16px;
        }
      }
    }
  }
}