@import '../../../../../static/styles/mixins.scss';

.main {
  box-sizing: border-box;

  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;

  width: 100%;
  height: 100%;
  min-height: 0;

  padding: 1.6rem 11.6rem 1.6rem 1.6rem;
  
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  .container {
    max-width: 128rem;

    display: flex;
    flex-direction: column;

    video.video {
      position: static;
      width: 100%;
      height: auto;
    }
    .name {
      font-size: 1.5em;
      font-weight: 600;
      font-family: 'Roboto', Arial, Helvetica, sans-serif;
    }
    .description {
      height: 2.375em;
      @include multiline-ellipsis(2);
    }
    > * + * {
      margin-top: 1.6rem;
    }
  }

}
