@import '@pixelcanvas/ui/dist/variables';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0 2rem;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    max-height: 100%;
}

.imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    max-height: 80%;

    @media (max-height: 800px) {
        max-height: 30%;
    }

    @media (max-height: 300px) {
        display: none;
    }
}

.image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.text-container {
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 53rem;
}

.description.description {
    margin: 1.5rem 0 3rem;
    color: black;
}

.text-container .title {
    color: black;
    font-weight: 700;
}

.button {
    width: 16rem;
}
