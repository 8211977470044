@import '../../../../../static/styles/variables';

.video-chat-list {
  min-width: 20rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .panel-heading {
    font-size: 2.1rem;
    font-weight: bolder;
    padding: 1.5rem;
    height: 9.8rem;
    background: rgba(223,223,224,0.1);
    position: relative;
  }
  .panel-body {
    padding-left: 1.3rem;
  }
  .list-item {
    height: 15.5rem;
    width: 100%;
    border-radius: 1.25em;
    margin-top: 1rem;
  }
  .list-placeholder {
    border: '0 none #FFF';
    outline: 'none';
    width: '100%';
    flex: 1;
    overflow-y: 'auto';
  }
  .list-video {
    overflow: hidden;
  }
  .list-controls {
    flex-shrink: 0;
    margin: 0 !important;
    width: 100%;
    height: 6rem;
    padding: 1rem 0;
    justify-content: space-evenly;
    backdrop-filter: blur(3rem);
  }
  .invite-button {
    background-color: $gray-11;
    border: none;
    border-radius: 1.25em;
    color: white;
    font-size: 1.2rem;
    line-height: 1.6;
    font-weight: bold;
    cursor: pointer;
    width: fit-content;
    vertical-align: auto;
    padding: 0.6rem 1.6rem;
    margin-top: 1.5rem;
  }
  .invite-button:hover {
    background: #35353580 0% 0% no-repeat padding-box;
  }
}
