@import '@pixelcanvas/ui/dist/variables';

.card {
  .image {
    height: 21rem;

    .topLeft {
      position: absolute;
      top: 1.6rem;
      left: 2.4rem;
    }

    .topRight {
      position: absolute;
      top: 1.6rem;
      right: 2.4rem;
    }

    .bottomRight {
      right: 2.4rem;
      bottom: 1.6rem;
    }

    .bottomLeft {
      left: 2.4rem;
      bottom: 1.6rem;
    }

    .center {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
    }

    .edit {
      pointer-events: auto;
      display: none;
    }

    &:hover .edit {
      display: flex;
    }

    .options {
      display: none;
    }

    &:hover .options {
      display: block;
    }
  }

  .container {
    margin: none;
    padding: none;
    border: none;
  }

  .content {
    .name {
      margin-bottom: 0.8rem;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
    }

    .owner {
      margin-bottom: 2.4rem;
    }
  }
}

.link {
  display: flex;
  height: 2em;
  border-radius: 2em;
  width: 100%;
  background: $purple-2;
  align-items: center;
  font-size: 1.6rem;
  margin-top: auto;

  .label {
    margin-left: 1em;
    margin-right: 1em;
    overflow-x: scroll;
    scrollbar-width: none;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }

  }

  .icon {
    height: 100%;
    width: 1em;
    background: $purple-5;
    padding: 0 0.625em;
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
    margin-left: auto;
    cursor: pointer;

    path {
      fill: white;
    }

    &:hover {
      background: $purple-5;
    }
  }
}
