@import '../../../../static/styles/variables.scss';

.button {
  position: relative;
  cursor: pointer;
  width: 100%;
  color: white;
  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }
  .left-tab {
    position: absolute;
    height: 60%;
    width: 4px;
    top: 20%;
    border-radius: 0px 4px 4px 0px;
  }
  .content {
    position: absolute;
    padding: 0 10px 0 10px;
    top: 20%;
    left: 0;
    bottom: 0;
    right: 10px;
    height: 60%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;

    color: black;
    font-size: 12px;
    font-weight: 600;
    padding: 0 5px;

    .logo {
      width: 35%;
      height: 35%;
      display: flex;
      justify-content: center;
      
      display: flex;
      width: 40px;
      min-width: 40px;
      height: 40px;
      min-height: 40px;
      margin-bottom: 5px;
      align-items: center;
    }
  }
  .logo {
    background: white;
    border-radius: 50%;
  }
  &:hover:not(.disabled):not(.selected) {
    opacity: 0.5;
  }
  &:hover:not(.disabled):not(.selected) {
    opacity: 0.5;

    .content {
      .logo {
        background: white;
        border-radius: 50%;

        path {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &.online {
    .content {
      color: $gray-11 !important;

      .logo {
        background: $red-7 !important;
        border-radius: 50%;
      }
    }
  }

  &.selected {
    .content {
      .logo {
        path {
          fill: white;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &.disabled {
    .content {
      color: $gray-10;

      .logo {
        path {
          fill: $gray-10;
        }
      }
    }
  }
}
