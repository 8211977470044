@import "../../../../../static/styles/variables.scss";

.modal.modal {
  box-sizing: border-box;
  height: auto;
  width: 72rem;
  padding: 5.4rem 11.2rem;
  &::-webkit-scrollbar {
    display: none;
  }
}
