.image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  .video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
