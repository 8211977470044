.head {
  display: flex;
  flex-direction: column;

  .logo {
    flex: 1 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;

    padding-left: 3.2rem;

    img {
      width: auto;
      height: 3.8rem;

      margin-right: auto;
    }
  }
}

.nameAndOptionsSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.4rem;

  .name {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }
}

.logoAndShareSection {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  .logo {
    height: 4.8rem;
    width: 9.6rem;

    object-fit: cover;
  }

  .share {
    margin-right: 1.6rem;
  }
}

.share {
  border: 0;
  margin: 0;
  padding: 0;
  background: none;
  cursor: pointer;
}
