.container {
  position: absolute;
  margin: auto;
  inset: 0;
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  pointer-events: auto;
}

.closeButton {
  position: relative;
  background-color: #00000099;
  border: none;
  box-shadow: none;
  width: 1.6em;
  height: 1.6em;
  font-size: 1.25rem;
  border-radius: 50%;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  outline: none;
  color: #f4f4f4;
}

.emojiButton {
  text-transform: capitalize;
  box-sizing: content-box;
  font-weight: 600;
  font-size: 1.25rem;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: #000000cc;
  border-radius: 9999px;
  color: #f4f4f4;
  min-width: 8rem;
  background-image: none;
  -webkit-appearance: button;
  margin: 0;
  font-family: Heebo, Roboto, Arial, Helvetica, sans-serif;
  border: 0;
  cursor: pointer;
}