@import '../../../../static/styles/variables';

.container {
  height: 100%;
  width: 100%;
  overflow: none;

  // This element is inside VideoStream component
  // so we have to select it by element type
  video {
    object-fit: cover;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }

  :global(.fullscreen) {
    .fullscreen-btn {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
      background-color: transparent;
      border: 0;
      outline: 0;

      .fullscreen-icon {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        height: $fullscreen-button-size;
        width: $fullscreen-button-size;

        path {
          fill: $purple-19;
        }
      }

      &.enter {
        right: 78px;
      }
    }
  }
}
