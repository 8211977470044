.contact-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .panel-heading {
    font-size: 32px;
    padding: 15px;
    background: rgba(223,223,224,0.1);
    .search-bar {
      flex-shrink: 0;
      height: 50px;
      padding: 10px 0 0;
    }
  }
  .list-items {
    width: 100%;
    height: calc(100% - 132px);
    flex: 1;
    .list-placeholder {
      height: 30px;
      width: 95%;
      background: lightgray;
      margin: auto;
    }
  }
  .no-attendees-placeholder {
    padding: 10px;
    text-align: center;
  }
}
