@import "@pixelcanvas/ui/dist/variables.scss";

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .logo {
    max-width: 14rem;
  }

  .organization {
    margin-right: auto;
  }
}
