@import '../../../../static/styles/variables.scss';

.container {
  transition: transform 0.2s, width 0.2s, min-width 0.2s;
  position: relative;
  z-index: 2;
  overflow: visible;
}

.sidebar {
  width: 10rem;
  height: 100%;
  font-family: "Prelia", "Roboto", Arial, Helvetica, sans-serif;
  font-weight: 400;
  user-select: none;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 3px 0 6px rgb(0 0 0 / 16%);
  backdrop-filter: blur(36px);
  position: absolute;
  right: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  > div:last-of-type {
    margin-top: auto;
  }
  .logo {
    width: 91px;
    height: 91px;
    object-fit: contain;
  }
}

.collapseButton {
  position: absolute;
  right: -7.9rem;
  @media (max-width: $md) {
    right: -5.9rem;
  }
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;

  transform: translateX(1rem);
  &.collapsed {
    transform: translateX(-1rem);
  }
  transition: transform 0.2s;
}
