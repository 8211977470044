@import '@pixelcanvas/ui/dist/variables.scss';

.content {
  width: 100%;
  height: 79%;
}

.mainContent {
  padding-top: 4.8rem;
  min-width: 0;
  flex: 1 1 auto;
}

.save {
  width: 100%;
  :first-child {
    margin-left: 37rem;
  }
  @media screen and (max-width: $breakpoint-md) {
    flex-direction: row;
    align-items: center;

    > :first-child {
      margin-left: 4rem;
    }
  }
}

.notificationContainer {
  top: 6.4rem;
}

.header {
  width: inherit;
}
