@import "../../../../static/styles/variables.scss";

.modal.modal {
  padding: 5.6rem 11.2rem 9.6rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  > :first-child {
    margin-bottom: 8rem;
  }
}

.title {
  font-size: 3rem;
  line-height: 1.625;
  font-weight: bold;
  margin-bottom: 5rem;
}

.small-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin: 0 2rem;
  }
  > :first-child {
    margin-left: auto;
  }
  > :last-child {
    margin-right: auto;
  }
}

.divider {
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 100%;
  display: table;
  white-space: nowrap;
  &:before,
  &:after {
    border-top: 1px solid $gray-20;
    content: "";
    display: table-cell;
    position: relative;
    top: 0.5em;
    width: 50%;
  }
  &:before {
    right: 1.5%;
  }
  &:after {
    left: 1.5%;
  }
}
