.container {
  position: absolute;
  top: 0;
  left: 50%;
  height: fit-content;
  transform: translate(-50%, 100%);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  pointer-events: none;

  transition: opacity 0.5s ease-in-out;

  opacity: 1;

  &.hide {
    opacity: 0;
  }
}

.whiteBorder {
  background-color: white;
  width: 1.2rem;
  z-index: 1;
  position: relative;
  box-shadow: 2px 3px 10px #ffffff80;
  box-sizing: border-box;
}

.textContainer {
  padding: 1.4rem 3.7rem;
  background: #000000cc 0% 0% no-repeat padding-box;
  border: 1px solid white;
  border-left: none;
}
