@import "../../static/styles/variables";

.container {
  display: flex;
  padding: 0 auto;
  border-top: 1px solid $purple-3;
  align-items: center;
  background: $purple-4;
  z-index: 10;
  height: 8rem;
  min-height: 8rem;
  width: 100%;
  .button {
    margin: 2rem 2rem 2rem 6rem;
  }
  .label {
    margin: 2rem 0;
    font-style: italic;
    color: $gray-1;
    line-height: 3rem;
  }
}
