@import "../../../../static/styles/variables.scss";

.title {
  color: $gray-11;
  font-size: 3rem;
  font-weight: 700;
}

.subtext {
  color: $gray-11;
  font-size: 1.4rem;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}

.content {
  min-height: 20vh;
}

.modal-actions {
  margin-top: auto;
  padding-top: 6rem;

  * {
    width: 13rem;
  }
}

.modal {
  min-height: 35vh;
  width: 95rem;
  padding-left: 6.4rem;
  padding-right: 6.4rem;
}

.refresh {
  margin-right: 0.8rem;
  height: 1.6rem;
  width: 1.6rem;
  fill: white;
}

.container {
  flex: 1 1 auto;
}

.table {
  width: 100%;
  table-layout: fixed;
}

.header {
  position: sticky;
  top: 0;
  padding-left: 0.5em;
  padding-right: 2rem;
  font-size: 2rem;
  font-weight: 600;
  line-height: 2;
  text-transform: uppercase;
}

.body {
  display: block;
  max-height: 40rem;
  overflow-y: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.head,
.body tr {
  display: table;
  table-layout: fixed;
  width: 100%;
  margin-bottom: 0.4rem;
}

.session {
  width: auto;
  text-align: left;
}

.count {
  width: 15%;
  text-align: center;
}

.select {
  width: 2rem;
}

.row-data {
  cursor: pointer;
  background-color: rgba($color: white, $alpha: 0.3);
  color: $gray-11;
  border-radius: 0.5rem;

  &:hover {
    background-color: rgba($color: white, $alpha: 0.8);
    font-weight: 800;

    .item {
      color: $gray-11;
      font-weight: 800;
    }
  }

  .item {
    font-size: 1.6rem;
    line-height: 2;
    padding: 0.3em 1em;
    vertical-align: middle;
    color: inherit;
  }
}

.empty {
  padding-top: 1em;
}

.disabled {
  color: $gray-27;
  cursor: not-allowed;
  pointer-events: none;
}

.icon {
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 0.8rem;
  fill: $gray-11;
}

.selected {
  background-color: $gray-11;
  color: white;
}

.player-count {
  text-align: center;
  width: 15%;
  color: inherit;
}

.checkicon {
  vertical-align: center;
  height: 1.6rem;
  width: 1.6rem;
  margin-left: 3rem;
}

.full-container {
  width: 2rem;
  margin-right: 1rem;
}

.server-name-container {
  display: flex;
  flex-direction: row;
}

.player-count-container {
  position: absolute;
  right: 4.5rem;
}