@import './../../../../static/styles/variables';

.livestream-chat-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);

  .tabs {
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    flex-direction: row;
    width: 100%;

    .tab {
      flex-direction: row;
      border-bottom: 3px solid white;
      color: $gray-11;
      font-size: 16px;
      width: 50%;
      text-align: center;
      cursor: pointer;
      padding-top: 27px;
      height: 44px;
    }

    .selected {
      border-bottom: 3px solid $gray-11;
      font-weight: bold;
    }
  }

  .chat-area {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}