@import "../../../../static/styles/variables.scss";

.modal-actions {
  display: flex;
  flex-direction: row;
  margin: auto 0 0 0;
  text-align: center;
  align-self: flex-end;
}

.modal-actions > button {
  margin-left: 3rem;
}

.modal-actions > button:first-child {
  margin-left: 0;
}
