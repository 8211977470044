@import "../../../../../static/styles/variables.scss";

.chat-message-bubble {
  margin-bottom: 1rem;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 1.5rem;

  &:not(.sent-by-user) {
    padding-right: 4rem;

    .chat-data {
      padding-left: 3.5rem;
    }
  }

  &.sent-by-user {
    margin-left: auto;
    padding-left: 4rem;
    width: fit-content;
    width: -moz-fit-content;

    a:visited {
      color: $purple-2;
    }

    &.attendee {
      padding-left: 5rem;
    }
  }

  .username {
    display: flex;
    align-items: center;
    color: $charcoal;
    line-height: 1.5;

    &.sent-by-user {
      margin: 0 3rem 0 auto;
      text-align: end;
    }

    .avatar {
      width: 3rem;
      height: 3rem;
      margin: 0.5rem;
      border-radius: 50%;
      object-fit: cover;
    }

    .username-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
    }
  }

  .chat-data {
    display: flex;
    margin-top: 0.5rem;

    .avatar {
      width: 3rem;
      height: 3rem;
      margin: 0.5rem;
      border-radius: 50%;
      object-fit: cover;
    }

    .message-text {
      background: white;
      color: $charcoal;
      padding: 1.2rem 1.5rem;
      line-height: 1.375;
      max-width: 80%;
      border-radius: 0.5rem;
      overflow-wrap: break-word;

      &.sent-by-user {
        background: $gray-24;
        color: white;
      }
    }

    .message-context-menu {
      position: relative;
      visibility: hidden;
      cursor: pointer;

      .menu-icon {
        line-height: 100%;
        fill: gray;
        height: 2rem;
        padding: 0.5rem;
      }
    }
  }

  .chat-data:hover .message-context-menu {
    visibility: visible;
  }
}