@import "../../../../static/styles/variables.scss";

.modal {
  min-width: 57rem;

  .title {
    color: $gray-11;
  }
}

.upload-container {
  display: flex;
  flex-direction: row;
  margin: 3rem 8rem 3rem 0;
}

.upload-rendered {
  display: flex;
  position: relative;
}

.spinner-container {
  position: absolute;
  bottom: 250px;
}

.platform-spinner {
  position: absolute;
}

.content-preview {
  width: 188px;
  height: 106px;
  object-fit: contain;
}

.uploadActionsContainer {
  display: flex;
  flex-direction: column;
  margin-left: 4.7rem;
  justify-content: center;

  .uploadActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.6rem;

    .uploadButton {
      display: flex;
      background: $gray-26;
      border: none;
      color: white;
      padding: 0.7em 2.4em;
      width: fit-content;
      height: fit-content;
      font-family: "Prelia", "Roboto", Arial, Helvetica, sans-serif;
      font-size: 1.2rem;
      border-radius: 3em;
      align-items: center;
      justify-content: center;
      user-select: none;
      cursor: pointer;

      &:hover {
        background-color: rgba($color: $gray-11, $alpha: 0.5);
      }
    }

    .selectnft {
      font-size: 1.2rem;
      background-color: $gray-26;
    }
  }

  .uploadDescription {
    margin-top: 1.6rem;
    font-size: 1.4rem;
    color: $gray-26;
  }

  .fileInput {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}

.file-input {
  visibility: hidden;
  width: 0;
  height: 0;
}

.error {
  color: red;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
