@import "./../../../../static/styles/variables";

.livestream-chat {
  height: 100%;
  display: flex;
  flex-direction: column;

  .questions-only {
    text-align: center;
    padding: 5px 10px;
    font-size: 12px;
    color: #707070;
  }

  .messages {
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
    height: 620px;
  }

  .input-container {
    display: flex;
    flex-direction: row;
    height: 64px;
    min-height: 64px;
    padding: 0 15px;
    border-top: 1px solid rgb(213, 213, 213);

    .chat-input {
      display: flex;
      border: none;
      background: white;
      // margin: auto 0;
      height: 30px;
      flex-grow: 1;
      padding: 15px 10px;

      &:focus {
        outline: none;
      }
    }

    .send-arrow-container {
      margin: auto 0 auto 10px;
      cursor: pointer;
      height: 30px;
      width: 30px;
      padding: 5px;
      border-radius: 50%;

      .send-arrow {
        cursor: pointer;
        border-radius: 50%;
        width: 2.2rem;
        height: 2.2rem;
        padding: 9px;
        background-color: $gray-11;

        path {
          fill: white;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}