@import '@pixelcanvas/ui/dist/variables.scss';

.cards {
  padding-top: 3.6rem;
  padding-bottom: 3.6rem;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > :not(:last-child) {
    margin-bottom: 1.2rem;
  }

  @media screen and (min-width: $breakpoint-md) {
    flex-direction: row;
    align-items: center;

    > :first-child {
      margin-right: auto;
    }

    > :not(:first-child) {
      margin-left: 2rem;
    }

    > :not(:last-child) {
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    > :not(:first-child) {
      margin-left: 4rem;
    }
  }

  .sort {
    width: 30rem;
  }
}

.add {
  height: 1em;
  width: 1em;
  min-height: 1em;
  min-width: 1em;
  margin-right: 0.5em;
  path {
    fill: white;
  }
}
