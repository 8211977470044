.profile {
  margin-bottom: 1.6rem;
}

.upload {
  display: flex;
  margin: 1rem 0;
  .rendered {
    display: flex;
    position: relative;
  }
  .picture {
    width: 14.3rem;
    height: 14.3rem;
    object-fit: contain;
    border: 0.2rem dashed white;
    border-radius: 50%;
    padding: 3%;
  }
  .uploadButtonContainer {
    display: flex;
    flex-direction: column;
    margin-left: 4.7rem;
    justify-content: center;
    .description {
      margin-top: 1rem;
      font-size: 1.4rem;
      color: white;
    }
  }
}
