@import "../../../../static/styles/variables";

.bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: -1;
  background-color: #261342;
  background-size: cover;
  -webkit-background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  user-select: none;
  color: white;
}

.container {
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.title {
  font-size: 30px;
  font-weight: 600;
  margin: 50px 0 5px 0;
  text-align: center;
}

.heroImage {
  width: 1000px;
}

.button {
  margin: 30px auto;
  background: linear-gradient(100deg, #cc28d3 0%, #9300d9 100%) 0% 0% no-repeat padding-box;
  font-size: 18px;
  font-weight: 600;
  border-radius: 30px;
  padding: 10px 50px;
  cursor: pointer;
}
