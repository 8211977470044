@import "@pixelcanvas/ui/dist/variables";

.button {
  padding: 1em 1.5em;
  height: 4.4em;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  background-image: $gradient-card-default;

  border-radius: 8px;
  border: none;
  box-sizing: border-box;
  box-shadow: $shadow-lifted;
  &:hover {
    background-image: $gradient-card-hover;
  }
  &.selected {
    border: 1px solid $high-emphasis;
    background-image: $gradient-card-hover;
  }

  .picture {
    border-radius: 50%;
    width: 2em;
    height: 2em;
    padding: 0.45em;
    object-fit: cover;
    margin-right: 1.2em;
  }

  .defaultIcon {
    margin-right: 1.2em;
  }
}
