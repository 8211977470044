.main {
  position: relative;

  box-sizing: border-box;

  width: 100%;
  height: 100%;
  min-height: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    > * + * {
      margin-top: 0.8rem;
    }
  }

  .enter {
    font-size: 2rem;
    padding-left: 4em;
    padding-right: 4em;
  }
}

.previewImage {
  position: absolute;
  z-index: -100;

  height: 100%;
  width: 100%;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  object-fit: cover;
}

.note {
  position: absolute;

  bottom: 3.2rem;
}
