@import '../../../../static/styles/variables.scss';

.modal {
  min-width: 57rem;

  .title {
    color: $gray-11;
    margin-bottom: 2.4rem;
  }
}

.inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 2.4rem;

  width: 100%;

  .upload {
    margin-bottom: 0.4rem;
  }
}

.upload {
  cursor: pointer;
  margin-right: 1.6rem;
}

.input {
  display: none;
}

.icon {
  font-size: 2.4rem;
}

.link {
  flex: 1 1 auto;
}
