.roleRadioGroup {
  display: flex;
  flex-direction: column;
  margin: 1.8rem 0;
  row-gap: 1rem;
}

.roleRow {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  .__react_component_tooltip {
    &.place-right::before {
      border-top: none !important;
      border-bottom: none !important;
      left: 0px !important;
      top: 50% !important;
      margin-top: -9px !important;
    }
  }
}

.header {
  margin-top: 3rem;
}

.modal.modal {
  width: 72rem;
}

.content {
  row-gap: 1.2rem;
  display: flex;
  flex-direction: column;
}
