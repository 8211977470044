@import '../../static/styles/variables';

.container {
  .image-info {
    display: flex;
    flex-direction: column;
    color: $purple-5;
    font-size: 14px;
    font-weight: 600;
    margin-top: 30px;
  }
  .choose-image {
    display: flex;
    margin: 30px 0;
    .chosen-image {
      width: 150px;
      height: 100%;
      object-fit: contain;
      user-select: none;
    }
    .options-tab {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      color: white;
      overflow: hidden;
      .tabs {
        position: relative;
        display: flex;
        border-bottom: 1px solid $purple-6;
        margin-left: 40px;
        height: 30px;
        user-select: none;
        white-space: nowrap;
        .tab {
            position: absolute;
            height: calc(100% - 1px);
            cursor: pointer;
            &.selected {
                font-weight: 600;
                border-bottom: 3px solid $purple-9;
            }
            &.renders {
                margin-left: 200px;
            }
        }
      }
      .renders-carousel {
        display: flex;
        margin-left: 40px;
        height: calc(100% - 28px);
        align-items: center;
        .angle-icon {
          width: 30px;
          min-width: 30px;
          height: 30px;
          background: $purple-18;
          cursor: pointer;
          &.left {
              margin-right: 20px;
          }
          &.right {
              margin-left: 20px;
          }
          path {
              fill: white;
          }
        }
        .carousel {
          height: 90px;
          margin-top: 20px;
          display: flex;
          overflow-x: scroll;
          scrollbar-width: none;
          padding: 0;
          .carousel-image {
              margin-right: 20px;
              cursor: pointer;
              user-select: none;
              border: 1px solid #00000000;
              &.selected {
                  border: 1px solid white;
              }
          }
          &::-webkit-scrollbar {
              display: none;
          }
        }
      }
      .upload-your-own {
        margin: 30px 0 0 40px;
        .upload-button-container {
          display: flex;
          flex-direction: column;
          margin-left: 20px;
          justify-content: center;
          .file-input {
            visibility: hidden;
            width: 0;
            height: 0;
          }
        }
        .instructions {
            margin-top: 20px;
            font-style: italic;
        }
      }
    }
  }
}
