@import '../../static/styles/variables';

.landing-bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: -1;
  background-color: #261342;
  background-size: cover;
  -webkit-background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.landing-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  user-select: none;
  color: white;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;

  .landing-title {
    display: flex;
    flex-direction: column;
    margin: 0.5rem auto;
    font-size: 3.5rem;
    font-weight: 600;
    text-align: center;
  }

  .client-suggestion {
    margin: 0 auto 0.5rem auto;
    height: 10.5rem;
    text-align: center;
    font-size: 9rem;
    font-weight: 200;
  }

  .landing-description {
    margin: 0 auto;
    text-align: center;
  }

  .get-started {
    margin: 3rem auto;
  }

  .hero-image {
    width: 80%;
  }
}