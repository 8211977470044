@import "../../../../../static/styles/variables.scss";

.modal.modal {
  height: auto;
  width: 72rem;
  padding: 5.4rem 3.2rem;
  &::-webkit-scrollbar {
    display: none;
  }
}

.content {
  padding: 1rem 6rem 2rem;
}

.header {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 0.8rem;
  color: $gray-11;
}

.description {
  font-size: 1.4rem;
  line-height: 1.2;
  color: $gray-19;
}

.fields > * + * {
  margin-top: 2.4rem;
}

.actions {
  box-sizing: border-box;
  width: 100%;

  padding-right: 6rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.error {
  color: red;
}
