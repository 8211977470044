@import '../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  height: inherit;
  width: 65%;
  padding-top: 4rem;
}

.setting {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $purple-6;
  border-top: 1px solid $purple-6;
  padding: 3rem 0;
}

.selected {
  font-weight: bold;
}

.label {
  padding-right: 0.5rem;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.33;
}

.tooltip {
  margin-right: auto;
}

.description {
  color: white;
  font-size: 1.6rem;
  line-height: 1.375;
  margin-bottom: 3rem;
}

.options-tab {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  color: white;
  overflow: hidden;
}

.tabs {
  position: relative;
  display: flex;
  border-bottom: 1px solid $purple-6;
  height: 3rem;
  user-select: none;
  white-space: nowrap;
}

.tab {
  height: calc(100% - 1px);
  cursor: pointer;
  padding: 0 1rem;

  &.selected {
    font-weight: 600;
    border-bottom: 0.3rem solid $purple-9;
  }

  &.renders {
    margin-left: 20rem;
  }
}

.domain-container {
  display: flex;
  flex-direction: column;
  height: inherit;
}

.footer {
  width: 100%;
  margin-top: auto;
}
