@import '../../../../static/styles/variables';

.livestream-chat-message-bubble {
  font-size: 16px;
  width: fit-content;
  margin: 0 5px 0 5px;

  a:hover {
    color: $gray-3;
  }

  .chat-data {
    display: flex;
    margin-top: 5px;

    .avatar {
      width: 30px;
      height: 30px;
      margin: 5px;
      border-radius: 50%;
      object-fit: cover;
    }

    .text {
      display: flex;
      flex-wrap: wrap;

      .username {
        font-weight: bold;
        padding: 5px;
        padding-top: 8px;
      }

      .message-text {
        color: #707070;
        padding-top: 8px;
        max-width: 80%;
        word-break: break-word;
      }
    }

    .message-context-menu {
      position: relative;
      visibility: hidden;
      cursor: pointer;

      .menu-icon {
        line-height: 100%;
        fill: gray;
        height: 20px;
        padding: 5px;
      }
    }
  }

  .chat-data:hover .message-context-menu {
    visibility: visible;
  }
}