@import '../../static/styles/variables';

.container {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  z-index: 100;
  .icon {
    animation-name: platform-spinner-animation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    position: absolute;
    height: 80px;
    width: 80px;
    margin: auto;
    fill: $gray-1;
    align-self: center;
    @keyframes platform-spinner-animation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.fixed {
  position: fixed;
}
