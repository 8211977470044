@import '@pixelcanvas/ui/dist/variables';

.container {
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;

  overflow: hidden;

  pointer-events: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: white;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}

.buttons {
  position: absolute;
  left: 3.2rem;
  bottom: 3.2rem;

  height: fit-content;
  width: fit-content;

  display: flex;
  flex-direction: row;
  align-items: flex-end;

  button {
    box-sizing: border-box;
    border: 0;
    margin: 0;
    padding: 0;
    background: none;

    font-size: 3rem;

    pointer-events: auto;
    cursor: pointer;

    &.globe {
      font-size: 4.4rem;
    }

    svg {
      filter: drop-shadow(0 0 1rem rgb(0 0 0 / 0.5));
      path {
        fill: rgba(#FFFFFF, 0.7);
      }
    }

    &:hover {
      svg path {
        fill: white;
      }
    }
  }
  button + button {
    margin-left: 3rem;
  }
}
