@import '../../../../styles/variables';

.stage-details {
  display: flex;
  flex-direction: column;
  color: white;
  overflow-y: auto;

  .section-label {
    color: white;
    font-size: 18px;
    font-weight: 600;
  }

  .top-side {
    display: flex;
    flex-direction: row;
    max-width: 1000px;
    align-items: center;
    margin: 10px 0;

    .page-info {
      flex-direction: column;
      width: 100%;

      .save-details-button {
        margin: 20px;
        margin-left: auto;
      }

      .disabled {
        pointer-events: none;
        background: gray;
      }
    }
  }

  .layout-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    height: fit-content;
  }

  .add-content-section {
    display: flex;
    flex-direction: row;

    .add-content-column {
      min-width: 500px;

      .add-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        .add-button {
          width: 20px;
          height: 20px;
          margin-left: 20px;

          path {
            fill: white;
          }
        }
      }

      .contents {
        flex: 1;
        overflow-y: auto;
        width: 100%;

        .list-item {
          width: 100%;
          height: 80px;
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;

          .titles {
            flex: 1;
            max-width: 450px;
            display: flex;
            flex-direction: column;
            padding-left: 5px;

            .content-title {
              padding: 5px;
              color: $purple-5;
              font-size: 18px;
            }

            .content-time {
              padding: 5px;
              color: white;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .trash-icon {
            width: 20px;
            height: 20px;
            margin: 0 20px 0 auto;
            visibility: hidden;
            cursor: pointer;

            path {
              fill: white;
            }
          }

          &:hover {
            background: $purple-3;

            .trash-icon {
              visibility: visible;
            }
          }
        }
      }
    }
  }
}

.content-details-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-content-tooltip {
  margin-left: 1rem;
}