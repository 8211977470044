@import "../../../../static/styles/variables.scss";

$side-panel-padding: 10px;

.container {
  height: calc(100% - 20px);
  position: relative;
  margin-top: 10px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;

  .overlay-components {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    height: 100%;
    z-index: 4;
    right: 0;
    pointer-events: none;

    &.no-sidepanel {
      margin-right: $px-sidebar-width;
    }

    > * {
      pointer-events: auto;
    }

    .presenter-view {
      position: fixed;
      right: $px-sidepanel-width + $px-sidebar-width;
      left: 0;
      top: $header-height;
      bottom: 0;
    }

    .overlay-chat-window {
      position: relative;
      z-index: 1;
    }

    .overlay-chat-bubbles {
      width: 90px;
      height: 100%;
      overflow-y: hidden;
      overflow-x: visible;
      pointer-events: none;

      .bubble {
        height: 60px;
        width: 60px;
        min-height: 60px;
        box-sizing: border-box;
        border: 3px solid white;
        pointer-events: auto;

        &.focused {
          border: 3px solid $red-2;
        }
      }
    }

    .overlay-side-panel {
      display: none;
      width: $px-sidepanel-width;
      border-radius: 20px 0 0 20px;
      border: 1px solid rgba(255, 255, 255, 0.7);
      height: calc(100% - 2px);
      background: rgba(255, 255, 255, 0.7) !important;
      box-shadow: 0px 3px 6px #00000029;
      backdrop-filter: blur(36px);
      -webkit-backdrop-filter: blur(36px);
      transition-duration: 0.5s;
      z-index: 2;
      overflow-x: hidden;
      padding-right: 100px;

      .panel-heading {
        width: calc(100% - 20px);
        margin: 0 auto;
        background: rgba(255, 255, 255, 0.75) !important;
        border-bottom: 1px solid white;
        color: $gray-11;
        font-weight: bolder;
      }

      .panel-body {
        flex: 1;
        backdrop-filter: blur(36px);
      }
    }

    .visible {
      display: initial;
      background: rgba(255, 255, 255, 0.7) !important;
    }
  }

  .pixel-canvas-logo {
    width: 100%;
    padding: 5px 0;
    display: none;
  }

  .sidebar {
    transition: transform 0.2s;
    &.collapsed {
      transform: translateX($px-sidebar-width);
    }
  }

  .collapseButton {
    position: absolute;
    right: 12.1rem;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;

    transform: translateX(1rem);

    &.collapsed {
      transform: translateX(12.1rem);
    }

    transition: transform 0.2s;
  }
}

.svg-logo {
  padding: 8px 10px;
  width: 100%;
  height: 100%;
  fill: #5d0054;
}
