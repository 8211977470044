.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  :first-child {
    margin-left: auto;
  }
  .logoContainer {
    .logo {
      max-height: 100px;
      margin-top: 10px;
      margin-right: 6rem;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: auto;
    width: 27rem;
    .text {
      margin-top: 1em;
      color: white;
      text-shadow: 0px 0px 5px black;
      font-size: 1.8rem;
    }
    .button {
      margin-top: 2em;
      width: 23rem;
    }
  }
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  background-color: #282c3a;
}
