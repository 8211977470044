@import '@pixelcanvas/ui/dist/variables';

.card {
  width: 100%;

  border: 1px solid transparent;
  &.selected {
    border: 1px solid white;
  }

  .image {
    height: 21rem;

    .topLeft {
      position: absolute;
      top: 1.6rem;
      left: 2.4rem;
    }
    .topRight {
      position: absolute;
      top: 1.6rem;
      right: 2.4rem;
    }
    .bottomRight {
      position: absolute;
      right: 2.4rem;
      bottom: 1.6rem;
    }
    .bottomLeft {
      position: absolute;
      left: 2.4rem;
      bottom: 1.6rem;
    }
    .center {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
    }

    .edit {
      pointer-events: auto;
      display: none;
    }
    &:hover .edit {
      display: flex;
    }

    .options {
      display: none;
    }
    &:hover .options {
      display: block;
    }

    .games {
      display: flex;
      flex-direction: row;
      align-items: center;

      > * + * {
        margin-left: 0.8rem;
      }

      .icon path {
        fill: white;
      }
    }
  }

  .container {
    margin: none;
    padding: none;
    border: none;
  }

  .content {
    .name {
      margin-bottom: 0.8rem;
    }

    .owner {
      margin-bottom: 2.4rem;
    }
  }
}

.link {
  display: flex;
  height: 2em;
  border-radius: 2em;
  width: 100%;
  background: $purple-3;
  align-items: center;
  font-size: 1.6rem;

  .label {
    margin-left: 1em;
    margin-right: 1em;
    overflow-x: scroll;
    scrollbar-width: none;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }

  }
}
