@import '../../styles/variables';

.container {
  width: 880px;
  height: calc(100% - 160px);
  overflow-y: auto;
  padding: 30px 200px 30px 60px;
  color: white;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  .setting {
    display: flex;
    flex-direction: row;

    .label {
      padding-right: 5px;
      width: 200px;
    }
  }
}
