.input {
  margin-bottom: 2rem;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 1.6rem;

  .label {
    margin-right: 0.8rem;
  }
}

.preview {
  width: 100%;
  max-width: 32rem;
  aspect-ratio: 16/9;
  object-fit: contain;
  flex: 1 1 auto;
  min-width: 0;
}

.upload {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  .button {
    margin-left: 2rem;
    .label {
      line-height: 1;
      min-width: fit-content;
    }
  }
}
