@import "../../../../static/styles/variables";

.container {
  display: flex;
  max-width: fit-content;
  background: $purple-8;
  color: $gray-1;
  padding: 0.375em;
  padding-left: 0.9em;
  border: 1px solid $purple-6;
  border-radius: 20px;
  font-size: 1.6rem;
  justify-content: space-between;
  align-items: center;
  margin-right: auto;
}

.input {
  background: none;
  outline: none;
  border: none;
  color: white;
  max-width: 18ch;
  font-size: inherit;
  &::placeholder {
    color: $gray-13;
    font-style: italic;
  }
}

.icon {
  width: 13px;
  height: 13px;
  margin-right: 10px;
  path {
    fill: $gray-13;
  }
}
