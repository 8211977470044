@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: 'Prelia';
  src:
    local('Prelia'),
    url('../../assets/fonts/Prelia/Prelia.woff2') format('woff2'),
    url('../../assets/fonts/Prelia/Prelia.woff') format('woff'),
    url('../../assets/fonts/Prelia/Prelia.ttf') format('truetype'),
    url('../../assets/fonts/Prelia/Prelia.otf') format('opentype');
}

@font-face {
  font-family: "Heebo";
  src:
    url("../../assets/fonts/Heebo/Heebo-Thin.woff2") format("woff2"),
    url("../../assets/fonts/Heebo/Heebo-Thin.ttf") format("ttf");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Heebo";
  src:
    url("../../assets/fonts/Heebo/Heebo-ExtraLight.woff2") format("woff2"),
    url("../../assets/fonts/Heebo/Heebo-ExtraLight.ttf") format("ttf");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Heebo";
  src:
    url("../../assets/fonts/Heebo/Heebo-Light.woff2") format("woff2"),
    url("../../assets/fonts/Heebo/Heebo-Light.ttf") format("ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Heebo";
  src:
    url("../../assets/fonts/Heebo/Heebo-Regular.woff2") format("woff2"),
    url("../../assets/fonts/Heebo/Heebo-Regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Heebo";
  src:
    url("../../assets/fonts/Heebo/Heebo-Medium.woff2") format("woff2"),
    url("../../assets/fonts/Heebo/Heebo-Medium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Heebo";
  src:
    url("../../assets/fonts/Heebo/Heebo-SemiBold.woff2") format("woff2"),
    url("../../assets/fonts/Heebo/Heebo-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Heebo";
  src:
    url("../../assets/fonts/Heebo/Heebo-Bold.woff2") format("woff2"),
    url("../../assets/fonts/Heebo/Heebo-Bold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Heebo";
  src:
    url("../../assets/fonts/Heebo/Heebo-ExtraBold.woff2") format("woff2"),
    url("../../assets/fonts/Heebo/Heebo-ExtraBold.ttf") format("ttf");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Heebo";
  src:
    url("../../assets/fonts/Heebo/Heebo-Black.woff2") format("woff2"),
    url("../../assets/fonts/Heebo/Heebo-Black.ttf") format("ttf");
  font-weight: 900;
  font-style: normal;
}
