.link {
  border: 0;
  background: none;
  margin: 0 1.6rem;
}

.icon {
  height: 2.2rem;
  width: 2.2rem;
  path {
    fill: white;
  }
}
