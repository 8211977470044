@import '../../../../static/styles/variables';

.page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    user-select: none;
    color: white;
}

.container {
    width: 700px;
}

.title {
    font-size: 30px;
    font-weight: 600;
    margin: 50px 0 5px 0;
    text-align: center;
    color: black;
}

.input {
    font-size: 30px;
    font-weight: 600;
    margin: 20px 0 35px 0;
    text-align: center;
    color: white;
}

.details {
    margin-top: 20px;
    text-align: center;
    white-space: pre-wrap;
    color: white;

    &.support {
        font-style: italic;
    }
}

.button {
    margin: 30px auto;
    font-size: 25px;
    padding: 10px 50px;
    border-radius: 30px;
    align-items:center;
}

.hero-image {
    width: 1000px;
}

.submit {
    display: flex;
    background: $gray-11;
    border: none;
    color: white;
    padding: 0.7em 3em;
    width: fit-content;
    height: fit-content;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 3em;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;

    &:hover {
      background-color: rgba($color: $gray-11, $alpha: 0.5);
    }
  }

  .error {
    color: red;
  }