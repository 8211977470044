@import '@pixelcanvas/ui/dist/variables.scss';

.image {
  height: 21rem;
}

.imageHover {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(180deg, #000000C7 0%, #00000027 24%, #00000000 73%, #000000C7 100%);
}

.container {
  height: 31rem;
  border-radius: 0.4rem;

  .content {
    width: 100%;
    padding: 5.4rem 4.4rem;
    position: relative;

    .name {
      margin-bottom: 0.7rem;
      font-family: 'Prelia', Arial, Helvetica, sans-serif;
      font-size: 2.6rem;
    }

    .created {
      margin-bottom: 1.6rem;
    }

    .assignedModels {
      margin-bottom: 0.8rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      > * {
        margin-right: 0.5rem;
      }
    }

    .models {
      max-height: 2em;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 1.6rem;
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: 100%;

      .column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 0.5rem;
        flex: 50%;

        .info {
          margin-bottom: 1.2rem;
          display: flex;
          flex-direction: row;
          align-items: center;

          .text {
            height: 1em;
            margin-left: 0.5em;

            color: $medium-emphasis;
            font-size: 1.4rem;
            font-family: 'Roboto', 'Open Sans', Arial, Helvetica, sans-serif;
          }
        }
      }
    }

    .lastEdited {
      position: absolute;
      right: 1.2rem;
      bottom: 1.2rem;

      color: $low-emphasis;
      font-size: 1.2rem;
      font-family: 'Roboto', 'Open Sans', Arial, Helvetica, sans-serif;
      font-style: italic;
    }
  }
}

.moreOptions {
  position: absolute;
  top: 2.4rem;
  right: 1.2rem;
  display: none;
}

.content:hover .moreOptions {
  display: block;
}

.icon {
  width: 1em;
  height: 1em;
  path {
    fill: $medium-emphasis;
  }
}
