$bg-color: rgb(96 96 96);

.input {
  &[type="checkbox"] {
    /* Hide native checkbox input */
    appearance: none;
    margin: 0;

    font: inherit;
    color: $bg-color;
    border-radius: 2px;

    border: 2px solid white;
    background-color: $bg-color;

    width: 1em;
    height: 1em;

    &:checked {
      color: white;
      border: 2px solid white;
      background-color: white;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10.42 7.5'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:black;%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cpath class='cls-1' d='M10.42.75a.77.77,0,0,1-.21.53l-6,6a.74.74,0,0,1-.53.22.7.7,0,0,1-.52-.22l-3-3A.75.75,0,0,1,.74,3a.71.71,0,0,1,.53.22L3.72,5.69,9.15.22a.74.74,0,0,1,1.27.53Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 0.875em;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}
