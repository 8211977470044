@import "../../../../../static/styles/variables.scss";

.card {
  position: relative;
  margin: 1rem 1rem;
  width: fit-content;
  min-height: 25.8rem;
  border: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.1s ease-in-out;
  &:hover {
    box-shadow: 0px 3px 15px #00000059;
  }
  .icon {
    width: 20rem;
    height: 20rem;
    object-fit: cover;
    align-self: center;
  }
  .description {
    margin: 0;
    padding: 0.8rem;
    max-width: 20rem;
    box-sizing: border-box;
    p {
      margin: 0;
      margin-right: auto;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: fit-content;
      max-width: 100%;
      box-sizing: border-box;
    }
    .collection {
      color: grey;
      margin-bottom: 0.6rem;
    }
    .name {
      font-weight: bold;
    }
  }
}
