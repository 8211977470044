.button {
  pointer-events: auto;

  background-color: white;
  color: black;
  font-family: inherit;
  font-weight: 600;

  font-size: 2.4rem;
  border-radius: 2em;
  border: 0;
  padding: 0.5em 2em;

  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}
