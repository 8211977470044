@import '@pixelcanvas/ui/dist/variables';
@import '../../../../static/styles/mixins.scss';

.container {
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  width: 100%;

  overflow: hidden;

  pointer-events: auto;

  background-color: rgba(#000000, 0.5);
  backdrop-filter: blur(1.6rem);

  color: white;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 3.6rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.header {
  box-sizing: border-box;

  position: relative;

  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  background-color: rgba(#000000, 0.5);
}

.top {
  box-sizing: border-box;

  position: relative;

  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 3.2rem 14rem;
  
  @media screen and (max-height: 400px) {
    padding: 0.8rem 6rem;
  }

  .back {  
    position: absolute;
  
    top: 0;
    left: 3.2rem;

    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    .button {
      font-size: 1.6rem;
    }
  }

  .text {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 3.6rem;
    font-weight: 400;
  }
}

.bottom {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs {
  max-width: 140.2rem;

  width: 100%;

  display: flex;
  flex-direction: row;

  gap: 6.4rem;
}

.tab {
  font-size: 2.4rem;
  padding: 0.25em 1em;
  
  @media screen and (max-height: 400px) {
    font-size: 2rem;
  }

  background: none;
  border: none;
  color: inherit;

  cursor: pointer;

  &.selected {
    border-bottom: 0.4rem solid white;
  }

  &:hover {
    opacity: 0.7;
  }
}

.content {
  box-sizing: border-box;

  flex: 1 1 auto;
  padding: 1.6rem;
  overflow-y: auto;
  max-height: 100%;
  width: 100%;

  @include scrollbar;
}

.cards {
  max-width: 140.2rem;

  margin: auto;
  padding: 6.4rem 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  gap: 6.4rem;
}

.card {
  background: none;
  border: none;
  color: inherit;

  font-size: 2.4rem;

  cursor: pointer;

  .span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 0.2rem;
    border: 1px solid grey;
  }
}

.thumbnail {
  background: none;

  box-sizing: border-box;

  border-radius: 0.2rem 0.2rem 0 0;

  background-color: rgba(white, 0.2);
  backdrop-filter: blur(0.4rem);

  width: 100%;
  height: 20rem;

  position: relative;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: none;
    justify-content: center;
    align-items: center;
    .icon {
      font-size: 6.4rem;
      opacity: 0.7;
    }
  }
}

.span:hover .overlay {
  display: flex;
} 

.title {
  box-sizing: border-box;

  border-radius: 0 0 0.2rem 0.2rem;
  background-color: rgba(#000000, 0.5);

  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;

  width: 100%;

  padding: 0.8rem 1.6rem;

  min-width: 30rem;
  min-height: 8rem;

  text-align: left;
}

.videocontent {
  height: 80%;

  padding: 1.6rem 0;

  video.video {
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    position: static;
  }
}