@import '../../../../static/styles/variables';

.notifications-panel {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .panel-heading {
    background: rgba(223, 223, 224, 0.1);
    font-size: 3.2rem;
    padding: 1.5rem;

    .mark-all-read {
      background-color: $gray-11;
      border: none;
      border-radius: 1.25em;
      color: white;
      font-size: 1.2rem;
      line-height: 1.6;
      font-weight: bold;
      cursor: pointer;
      width: fit-content;
      vertical-align: auto;
      padding: 0.6rem 1.6rem;
      margin-top: 1.5rem;
    }

    .mark-all-read:hover {
      background: #35353580 0% 0% no-repeat padding-box;
    }
  }

  .filler {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    font-size: large;
  }

  .list-items {
    width: 100%;
    flex: 1;
    overflow-y: auto;

    .list-item {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      min-height: 6rem;
      justify-content: left;
      align-items: flex-start;
      margin: 1.6rem 1.5rem 0 1.5rem;
      padding: 0 0.5rem;
      position: relative;
      cursor: pointer;
      border-bottom: 1px solid white;

      .header {
        padding-bottom: 0.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;

        .unread-dot {
          background: $purple-2;
          border-radius: 50%;
          height: 1rem;
          width: 1rem;
          margin: 0.5rem;
        }

        .text {
          font-size: 2rem;
          font-weight: 600;
          color: $purple-1;
          flex: 1;
        }

        .dismiss-button {
          width: 2rem;
          height: 2rem;
          padding: 0.5rem;
          cursor: pointer;

          path {
            fill: $purple-1;
          }
        }
      }

      .time {
        padding: 1rem 0;
        font-size: 1.2rem;
      }
    }
  }
}