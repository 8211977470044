@import "../../../../static/styles/variables";

.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  font-family: "Heebo", "Roboto", Arial, Helvetica, sans-serif;

  .main {
    height: 100%;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .page {
      height: 100%;
      margin: auto;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .pixel-connect-overlay {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: $px-sidebar-width;
    z-index: 1;
  }
}
