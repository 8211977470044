@import '../../../../static/styles/variables';

.vod-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  .search-bar {
    display: flex;
    height: 30px;
    align-items: center;
    border: 1px solid $gray-1;
    width: 500px;
    margin: 10px auto 0 auto;

    .search-input {
      outline: none;
      border: none;
      padding: 0 10px;
      flex-grow: 1;
    }

    .search-icon {
      height: calc(100% - 10px);
      padding: 5px 10px;
      background: $gray-2;
      cursor: pointer;

      path {
        fill: $gray-3;
      }
    }
  }

  .tags {
    display: flex;
    margin: 10px 50px;

    .tag {
      margin: 0 10px;
      border: 1px solid $gray-1;
      padding: 5px 10px;
      border-radius: 20px;
      cursor: pointer;

      &.selected {
        background: $gray-1;
      }
    }
  }

  .content-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow-y: auto;

    .vod-item {
      width: 300px;
      height: fit-content;
      margin: 10px;
      cursor: pointer;
      background: white;
      border-radius: 20px;
      overflow: hidden;

      .thumbnail {
        width: 300px;
        height: 200px;
        object-fit: cover;
      }

      .content-info {
        padding: 15px;

        .content-title {
          font-size: 16px;
          font-weight: bold;
        }

        .content-description {
          margin-top: 10px;
          font-size: 16px;
        }
      }
    }
  }
}

.vod-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .vod-iframe {
    position: relative;
    width: 50%;
    padding-bottom: 28%;
    outline: none;

    .video {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}