$border-width: 0.125em;

.button {
  flex: 0 0 21%;

  box-sizing: border-box;
  font-family: 'Prelia', 'Open Sans', sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  width: fit-content;
  height: fit-content;
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
  cursor: pointer;

  &:hover .container:before {
    background: linear-gradient(90deg, #808080DB 0%, #FFFFFF00 94%, #FFFFFF00 100%);
  }
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 0.25em 1.75em 0.25em 0.25em;

  position: relative;
  background-image: linear-gradient(90deg, #000000B8 0%, #00000000 94%, #00000000 100%);
  background-clip: padding-box;
  border: solid 2px transparent;
  border-radius: 3em 0 0 3em;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border-width;
    padding: $border-width;
    border-radius: inherit;

    background: none;

    mask:
      linear-gradient(white 0 0) content-box,
      linear-gradient(white 0 0);
    -webkit-mask:
       linear-gradient(white 0 0) content-box,
       linear-gradient(white 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
  }

  &:before:hover {
    background: linear-gradient(90deg, #FFFFFFB8 0%, #FFFFFF00 94%, #FFFFFF00 100%);
  }
}

.label {
  color: white;
  text-shadow: 0 0.125em 0.1875em #FFFFFF63;

  margin-left: 0.5em;
}

.icon {
  box-sizing: border-box;
  border: $border-width solid white;
  background-image: url('/features/Visitor/components/MapsView/assets/images/icon_bg_default_square.png');
  box-shadow: 0 0.1875em 0.375em #00000029;

  min-width: 2.75em;
  min-height: 2.75em;
  width: 2.75em;
  height: 2.75em;
  border-radius: 50%;
  object-fit: none;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover .icon {
  background-image: url('/features/Visitor/components/MapsView/assets/images/icon_bg_hover_square.png');
}

.svg {
  width: 1.125em;
  height: 1.125em;
  path {
    fill: black;
  }
}
