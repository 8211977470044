@import './../../static/styles/variables';

.event-join-page {
  height: 100vh;
  width: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .spinner-icon {
    height: 200px;
    width: 200px;
    margin: auto;
    fill: $purple-2;
    align-self: center;
  }

  .error-message {
    margin: 20px;
  }
}