.image {
  height: 21rem;
}

.imageHover {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(180deg, #000000C7 0%, #00000027 24%, #00000000 73%, #000000C7 100%);
}

.content {
  width: 100%;
}

.name {
  margin-bottom: 0.7rem;
}

.owner {
  display: flex;
  flex-direction: row;
  align-items: center;

  .profile {
    height: 1.375em;
    width: 1.375em;
    object-fit: cover;
    border-radius: 1.375em;

    margin-right: 0.5em;
  }
}

.icon {
  height: 1em;
  width: auto;

  path {
    fill: white;
  }
}

.upperLeft {
  position: absolute;
  top: 1em;
  left: 1.25em;
  text-transform: capitalize;

  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin-right: 0.5em;
  }
}

.lowerLeft {
  position: absolute;
  bottom: 1em;
  left: 1.25em;

  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin-right: 0.5em;
  }

  .hours {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    > * {
      margin-right: 0.25em;
    }
  }
}

.lowerRight {
  position: absolute;
  bottom: 1em;
  right: 1.25em;

  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-left: 1.25em;

    > * {
      margin-left: 0.5em;
    }
  }
}
