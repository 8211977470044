@import '../../styles/variables';

.cta-details {
  display: flex;
  flex-direction: column;
  color: white;
  background: transparent linear-gradient(122deg, #3D3565 0%, #27204A 20%, #2D2552 75%, #382552 100%) 0% 0% no-repeat padding-box;
  width: 80%;
  padding: 30px 50px;

  .page-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .detail-label {
    margin: 10px 0 0 0;
    color: $purple-5;
    font-size: 14px;
    font-weight: 600;
  }

  .create {
    margin: 20px 0 0 auto;

    &.disabled {
      pointer-events: none;
      background: gray;
    }
  }
}