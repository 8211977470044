.landing-footer {
  pointer-events: none;
  position: absolute;
  display: flex;
  background: transparent linear-gradient(180deg, #00000000 0%, #000000CC 100%) 0% 0% no-repeat padding-box;
  width: 100%;
  height: 10rem;
  padding-bottom: 15rem;
  align-items: flex-end;
  bottom: 0;
  color: white;
  padding: 0 5rem 2rem 0;
  .terms-conditions {
    pointer-events: auto;
    margin-left: 5rem;
    cursor: pointer;
  }
  .pixel-canvas-year {
      margin: 0 5rem 0 auto;
  }
}
