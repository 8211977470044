.button {
  --hover-color: transparent;
  --button-color: transparent;
  --tab-color: white;
  --active-color: transparent;

  background-color: transparent;
  position: relative;
  cursor: pointer;
  width: 100%;
  border: 0;
  padding: 0;
  font-size: inherit;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-weight: 600;

  color: white;
  &.dark {
    color: black;
  }

  &:hover {
    opacity: 0.5;
  }

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  .tab {
    background-color: var(--tab-color);

    position: absolute;
    height: 60%;
    width: 4px;
    top: 20%;
    border-radius: 0px 4px 4px 0px;
  }

  .content {
    &.active {
      background-color: var(--active-color);
    }

    position: absolute;
    padding: 0 10px 0 12px;
    top: 20%;
    left: 0;
    bottom: 0;
    right: 4px;
    height: 60%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;

    color: white;
    &.dark{
      color: black;
    }
  }
}
