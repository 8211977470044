@import "../../../../../static/styles/variables.scss";

.channel-list-item {
  padding: 0 1.8em;

  .channel-list-item-header {
    display: flex;
    flex-direction: row;
    vertical-align: center;
    padding: 0.5rem 0 0.5rem 0;

    .title {
      color: $gray-11;
      width: 100%;
      line-height: 3rem;
      font-size: 1.7rem;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .menu {
      flex-shrink: 0;
      position: relative;
      cursor: pointer;

      .menu-icon {
        fill: $gray-5;
        height: 1em;
        padding: 0.5rem;
      }
    }
  }

  .description {
    color: $gray-11;
    font-size: 1.4rem;
    padding: 0 0 1rem 0;
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    white-space: pre-line;
  }

  .description::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  .footer {
    height: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0 1rem;

    .icons {
      flex: 1;
      display: flex;
      color: $gray-11;
      font-size: 1.2rem;

      .icon {
        height: 1em;
        padding: 0.5em;
        fill: $gray-11;
      }

      .participants-chip {
        display: flex;
        text-align: center;
        padding: 0.5em 0.5em 0.5em 0;
        height: 100%;

        .user-icon {
          height: 1em;
          margin-right: 0.5rem;
          fill: $gray-11;
        }
      }
    }

    .join-button {
      flex-shrink: 0;
      background-color: $gray-26;
      border: none;
      border-radius: 1.2em;
      color: white;
      padding: 0.6rem 1.6rem;
      font-size: 1.2rem;
      font-weight: bolder;
      text-align: center;
      text-decoration: none;

      svg {
        fill: white;
        height: 0.9rem;
        margin-left: 0.5rem;
        width: auto;
      }

      &:hover {
        opacity: 0.5;
        cursor: pointer;
      }
    }
  }
}