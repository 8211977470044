@import '../../../../static/styles/variables.scss';

.button {
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
  border: 0;
  padding: 0;
  height: fit-content;
  width: fit-content;
  background-color: #000000A6;
  border-radius: 3rem;
  .icon path {
    fill: #FFFFFFB0;
  }

  &.collapsed {
    background-color: #000000A6;
    border-radius: 0 3rem 3rem 0;
    .icon path {
      fill: #FFFFFFB0;
    }
    &.right {
      border-radius: 3rem 0 0 3rem;
    }
  }

  &:hover {
    background-color: #00000099;
    border-radius: 3rem;
    .icon path {
      fill: #FFFFFF;
    }
  }

  &.collapsed:hover {
    background-color: #000000;
    border-radius: 0 3rem 3rem 0;
    .icon path {
      fill: #FFFFFF;
    }
    &.right {
      border-radius: 3rem 0 0 3rem;
    }
  }
}

.span {
  width: 7.9rem;
  &.collapsed {
    width: 7rem;
    @media (max-width: $md) {
      width: 5rem;
    }
  }
  @media (max-width: $md) {
    width: 6rem;
    height: 4rem;
  }
  height: 5.2rem;
  transition: width 0.2s;

  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 1.5625em;
  height: 1.5625em;
}
