.channel-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .panel-heading {
    font-size: 32px;
    padding: 15px;
    background: rgba(223, 223, 224, 0.1);

    .search-bar {
      flex-shrink: 0;
      height: 50px;
      padding: 10px 0 0;
    }
  }

  .spacer-line {
    height: 2px;
    background: white;
  }

  .list-items {
    width: 100%;
    flex: 1;
    overflow-y: auto;

    .list-item {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      height: auto;
      justify-content: left;
      position: relative;

      :hover {
        transition: background-color 0.3s ease-in-out;
      }
    }

  }
}