@import "@pixelcanvas/ui/dist/variables";
@import "../../../../static/styles/mixins.scss";

.container {
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  width: 100%;
  min-width: 0;

  overflow: hidden;

  pointer-events: auto;

  background-color: rgba(#000000, 0.5);
  backdrop-filter: blur(1.6rem);

  color: white;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 3.6rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  box-sizing: border-box;

  position: relative;

  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.top {
  box-sizing: border-box;

  position: relative;

  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 3.2rem 14rem;

  @media screen and (max-height: 400px) {
    padding: 0.8rem 6rem;
  }

  .back {
    position: absolute;

    top: 0;
    left: 3.2rem;

    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    .button {
      font-size: 1.6rem;
      z-index: 1;
    }
  }
}

.contentContainer {
  padding: 5rem 20rem 5rem 10rem;
  box-sizing: border-box;
  gap: 3rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: center;
  flex: 1 1 auto;
  min-height: 0;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: $breakpoint-lg) {
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 5rem 10rem;
  }
}

.imageContainer {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100%;
  flex: 1 1 auto;

  @media screen and (max-width: $breakpoint-lg) {
    margin-top: 90%;
  }
}

.image {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.sideCardsContainer {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-width: 30rem;
  max-width: 30rem;
  gap: 3rem;
  margin-top: auto;
  margin-bottom: 10%;
}

.sideCard {
  box-sizing: border-box;
  max-height: 35rem;
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem;
  background-color: rgba(#000000, 0.5);
  width: 100%;
}

.descriptionContainer {
  box-sizing: border-box;

  flex: 1 1 auto;
  overflow-y: auto;
  max-height: 100%;
  width: 100%;

  @include scrollbar;
}

.sideCardContent {
  display: flex;
  flex-direction: row;
  padding: 1.6rem 0;
}

.iconLink {
  display: flex;
  flex-direction: row;
}

.icon {
  width: 2rem;
  height: 2rem;
  margin-right: 1.4rem;
  color: white;
}

.linkTextContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.linkText {
  max-width: 100%;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.link {
  text-transform: uppercase;
  color: #f4c93f;
}
