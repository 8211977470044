@import "../../../../../../static/styles/variables.scss";

.icon {
  width: 3.2rem;
  height: 3.2rem;
  margin-right: 3rem;
}

.error {
  color: $red-3;
}

.text {
  margin-right: auto;
  font-weight: bold;
}
