@import '../../../../styles/variables';

.details {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100% - #{$overview-header-height});
  width: 100%;
  color: white;
  padding: 1.6rem;
  overflow-y: auto;
}

.input {
  margin-bottom: 2rem;
}

.preview {
  height: 25rem;
  object-fit: contain;
}

.upload {
  display: flex;
  margin: 1em 0 2em;
  .button {
    margin-left: 2rem;
    .label {
      width: 12rem;
      line-height: 1;
    }
  }
}
