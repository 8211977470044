@import "../../../../static/styles/variables.scss";

.dropdown-options {
  color: $gray-1;
  display: flex;
  flex-direction: column;
  align-items: inherit;
  padding: 0.5rem 0;
  width: 100%;
}

.title {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 1.7rem 0 0.7rem 0;
  color: black;
  text-transform: uppercase;
  &.required {
    color: $red-1;
  }
}

.dropdown-option {
  display: flex;
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.26;
  background-color: white;
  border: 0.05rem solid $gray-18;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  outline: none;
  color: $gray-11;
  box-sizing: border-box;
  cursor: pointer;
  &.selected {
    border: 1px solid $gray-11;
  }
  &.required {
    border: 1px solid $red-1;
  }
  &.placeholder {
    font-style: italic;
    color: $gray-1;
  }
}

.input-footer {
  display: flex;
  align-items: center;
  font-style: italic;
  color: $red-1;
  font-size: 1.6rem;
  margin-top: 10px;
}

.triangle-error {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
  path {
    fill: $red-1;
  }
}
.arrow-icon {
  margin-left: auto;
  width: 1.6rem;
  height: 1.6rem;
}

.select-options-container {
  position: relative;
}

.select-options {
  width: 100%;
  position: absolute;
  background: white;
  top: 3px;
  box-shadow: 0px 0.5rem 1rem #00000033;
  max-height: 20rem;
  overflow-y: auto;
  z-index: 100;
  border-radius: 0.5rem;
  &::-webkit-scrollbar {
    display: none;
  }
}
.option {
  display: flex;
  color: $gray-11;
  align-items: center;
  height: 4rem;
  padding: 0 2rem;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    background: $gray-22;
  }
}
.check-box {
  width: 1rem;
  height: 1rem;
  background: none;
  padding: 0.2rem;
  margin-right: 1rem;
  border: 1px solid $gray-11;
  &.checked {
    background: $gray-22;
  }
  path {
    fill: none;
  }
}
