@import "../../../../../static/styles/variables.scss";

.title {
  color: $gray-11;
  font-size: 3rem;
  font-weight: 700;
  text-transform: capitalize;
}

.subtext {
  color: $gray-11;
  font-size: 1.8rem;
  margin-top: 2.3rem;
  margin-bottom: 5rem;
  margin-right: 5rem;
  white-space: pre-line;
}

.actions-container {
  margin-top: auto;
}

.modal {
  min-height: 25vh;
  max-width: 32vw;
}
