@import '../../../../../static/styles/variables';

.participant-screen-video {
  height: 100%;
  width: 100%;
  .screen-video-track {
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: black;
  }
  .participant-name {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    padding: 0 40px 0 40px;
    line-height: 40px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .audio-level-indicator {
    position: absolute;
    bottom: 4px;
    left: 15px;
  }
}
.participant-camera-video {
  width: 300px;
  height: 168px;
  position: absolute;
  overflow: hidden;
  top: 10px;
  right: 10px;
  z-index: 1;
  .camera-video-track {
    width: 100%;
  }
  .camera-audio-level-indicator {
    position: absolute;
    bottom: 4px;
    left: 15px;
  }
}
.presenter-controls {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  min-width: 200px;
  .button {
    width: 30px;
    height: 30px;
    padding: 10px;
    background: $purple-9;
    border-radius: 50%;
    cursor: pointer;
    .screen-share-icon {
      width: 80%;
      height: 80%;
      margin: 10%;
      path {
        fill: white;
      }
    }
  }
  .button:hover, .screen-share-active {
    background: $purple-2;
  }
}
