@import '../../../../../static/styles/variables.scss';

.contact-list-item {
  height: 100%;
  display: flex;
  justify-content: left;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid lightgray;
  cursor: pointer;

  .contact-avatar {
    position: relative;
    height: 4rem;
    width: 4rem;
    margin-left: 1rem;
    .online-badge {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      background: #C3E87F;
    }
    .contact-img {
      border-radius: 50%;
      height: 4rem;
      width: 4rem;
      object-fit: cover;
    }
  }
  .contact-name {
    padding: 1rem;
    flex: 1;
    color: black;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .menu {
    flex-shrink: 0;
    position: relative;
    .menu-icon {
      fill: $gray-24;
      height: 1.5rem;
      padding: 1rem;
    }
  }
}
