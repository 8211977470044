@import "../../../../static/styles/variables.scss";

.modal {
  min-width: 40vw;
  &::-webkit-scrollbar {
    display: none;
  }
}

.content {
  padding: 1rem 6rem 2rem;
}

.header {
  font-size: 3rem;
  font-family: "Prelia", "Roboto", Arial, Helvetica, sans-serif;
  line-height: 1.3;
  margin-bottom: 0.6rem;
  color: $gray-11;
}

.description {
  font-size: 1.4rem;
  line-height: 1.2;
  margin-bottom: 3rem;
  color: $gray-19;
}

.pictureLabel {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 1.5rem;
  color: $charcoal;
}

.visitorInput {
  margin-top: 1.6rem;
}

.uploadContainer {
  display: flex;
  margin: 1rem 0;
  .uploadRendered {
    display: flex;
    position: relative;
  }
  .dashedBorder {
    border: 2px dashed $gray-11;
    border-radius: 50%;
    background: none;
    width: fit-content;
    height: fit-content;
    padding: 0.5rem;
  }
  .picture {
    width: 12rem;
    height: 12rem;
    object-fit: contain;
    border: 2px solid #707070;
    border-radius: 50%;
    display: flex;
    justify-content: center;
  }
  .uploadActionsContainer {
    display: flex;
    flex-direction: column;
    margin-left: 4.7rem;
    justify-content: center;
    .uploadActions {
      display: flex;
      flex-direction: row;
      align-items: center;
      .uploadButton {
        display: flex;
        background: $gray-26;
        border: none;
        color: white;
        padding: 0.6em 2.4em;
        width: fit-content;
        height: fit-content;
        font-family: "Prelia", "Roboto", Arial, Helvetica, sans-serif;
        font-size: 1.2rem;
        border-radius: 3em;
        align-items: center;
        justify-content: center;
        user-select: none;
        margin-right: 1.6rem;
        line-height: 1.3;
        cursor: pointer;
        &:hover {
          background-color: rgba($color: $gray-11, $alpha: 0.5);
        }
      }
      .selectnft {
        background-color: $gray-26;
        margin-right: 1.6rem;
        border: 0;
      }
    }
    .uploadDescription {
      margin-top: 1.6rem;
      font-size: 1.4rem;
      color: $gray-26;
    }
    .fileInput {
      visibility: hidden;
      width: 0;
      height: 0;
    }
  }
}
.actions {
  padding-right: 6rem;
}
