@import '../../../../static/styles/variables';

.stage-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;

  &::-webkit-scrollbar {
    display: none;
  }

  .video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56%;
    background: $gray-5;

    .video {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }

  .title {
    color: $gray-11;
    font-size: 24px;
    font-weight: bold;
    padding: 10px 56px;
    line-height: 40px;
    background: white;
  }

  .description {
    color: #4a4a4a;
    font-size: 16px;
    padding: 10px 56px;
    line-height: 24px;
    min-height: 200px;
    background: white;
  }

  .live-stream-controls {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    font-weight: 600;

    .speaker-btn {
      border-radius: 20px;
      padding: 10px;
      user-select: none;
      cursor: pointer;
    }

    .join-speaker-room {
      border: 1px solid black;
    }

    .go-live {
      margin-left: 10px;
      border: 1px solid red;
      color: red;
    }
  }
}