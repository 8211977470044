.container {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  pointer-events: none;
  margin-top: 1rem;
}

.coinIcon {
  width: 2.1rem;
  height: 2.6rem;
  margin-right: 0.7rem;
}

.points {
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 0.36em;
  color: white;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  text-shadow: 0px 2px 2px #00000080;
  letter-spacing: 0px;
  text-align: left;
}
