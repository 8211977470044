@import '@pixelcanvas/ui/dist/variables.scss';

.page {
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    display: none;
  }

  min-height: 100%;
  width: 100%;
  background-color: transparent;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: white;
  font-family: 'Open Sans',
  Arial,
  Helvetica,
  sans-serif;
}

.background {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url('./background.png');
  background-position: center;
  background-size: cover;
  z-index: -100;
}

.topGradient {
  position: absolute;
  top: 0;
  width: 100%;
  height: 17%;
  background: linear-gradient(180deg, #661771 0%, rgba(102, 23, 113, 0.00) 100%);
  opacity: 0.5;
  z-index: -10;
}

.bottomGradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 15%;
  background: linear-gradient(180deg, rgba(9, 18, 82, 0.00) 0%, rgba(9, 18, 82, 0.92) 76.04%, #091252 100%);
  z-index: -10;
}

.logo {
  align-self: flex-start;
  height: 6.4rem;
  margin-left: 1.6rem;
  margin-top: 1.6rem;
  margin-bottom: auto;

  top: 4%;
  object-fit: contain;

  @media screen and (min-width: $breakpoint-sm) {
    height: 6.4rem;
  }

  @media screen and (min-width: $breakpoint-lg) {
    position: absolute;
    top: 0;
    left: 0;
    height: 8.8rem;
    margin-left: 4rem;
  }
}

.heroImage {
  position: relative;
  z-index: -11;
  margin-bottom: 1.6rem;
  width: 100%;
  pointer-events: none;

  bottom: 18%;

  @media screen and (min-width: $breakpoint-lg) {
    max-width: 100%;
    max-height: 30%;
    height: 28rem;
    height: 48vh;
    width: auto;
  }

  background-image: url('./hero-image.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.content {
  margin-bottom: 3.6rem;
  pointer-events: auto;

  top: 18%;

  @media screen and (min-width: $breakpoint-lg) {
    top: 10%;
    margin-top: 10rem;
    margin-top: 10vh;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  line-height: normal;
  font-size: 0.7rem;

  @media screen and (min-width: $breakpoint-lg) {
    font-size: 0.9rem;
  }

  .title {
    font-weight: 400;
    font-size: 3.8em;
    white-space: nowrap;
  }

  .banner {
    font-weight: 300;
    font-size: 5em;
    margin-top: 0.8rem;
    white-space: nowrap;

    @media screen and (min-width: $breakpoint-sm) {
      font-size: 9em;
    }
  }

  .button {
    padding: 0.64em 3.8em;
    font-size: 2.1em;
    margin-top: 1.6rem;
  }
}

.footer {
  box-sizing: border-box;
  margin-top: auto;
  margin-bottom: 1.6rem;

  bottom: 4%;
  width: 90%;
  font-size: 1.6rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 0;

  @media screen and (min-width: $breakpoint-sm) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: 0;
  }

  @media screen and (min-width: $breakpoint-lg) {
    position: absolute;
    width: 100%;
    padding: 0 4rem;
    bottom: 0;
  }

  .legal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.8rem;

    @media screen and (min-width: $breakpoint-sm) {
      flex-direction: row;
      gap: 3.2rem;
    }
  }

  .link {
    color: #78A9FF;
    text-decoration: none;
  }
}
