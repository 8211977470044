@import '../../../../../static/styles/variables.scss';

.description {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
  color: $gray-11;
  line-height: 1.6;

  a {
    font-weight: bold;

    color: inherit;
  }

  button {
    font-weight: bold;
    text-decoration: underline;

    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    cursor: pointer;
    outline: inherit;
  }
}
