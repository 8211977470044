@import '../../../../static/styles/variables';

.landing-bg {
  position: absolute;
  background-color: #261342;
  background-image: url('../../../../assets/images/landing-bg.png');
  height: 100%;
  width: 100%;
  z-index: -1;
}

.email-verification-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  user-select: none;
  color: white;

  .verification-container {
    width: 70rem;
    font-family: 'Open Sans', sans-serif;

    .verification-title {
      font-size: 3.8rem;
      font-weight: 400;
      margin: 5rem 0 2.5rem;
      text-align: center;
    }

    .verification-details {
      text-align: center;
      white-space: pre-wrap;
      font-size: 1.8rem;
      margin-bottom: 1.8rem;

      &.support {
        font-style: italic;
        margin-bottom: 4rem;

        a {
          color: #85b5d0;
          text-decoration: none;
        }
      }
    }

    .verification-button {
      margin: 0 auto 3.2rem;
    }
  }

  .hero-image {
    width: 100rem;
  }
}
