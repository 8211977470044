.roleRadioGroup {
  display: flex;
  flex-direction: column;
  margin: 1.8rem 0;
  row-gap: 1rem;
}

.roleRow {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  .__react_component_tooltip.place-right::before {
    border-top: none;
    border-bottom: none;
    left: 0px;
    top: 50%;
    margin-top: -9px;
  }
}

.header {
  margin-top: 3rem;
}

.modal.modal {
  width: 72rem;
}
