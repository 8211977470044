@import "@pixelcanvas/ui/dist/variables.scss";

.modal {
  min-width: 78rem;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-bottom: 3rem;
}