@import "../../../../../static/styles/variables";

.participant-video {
  position: relative;
  height: 15rem;
  margin: 0 1.5rem;
  &.is-talking {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border: 0.5rem solid $green-2;
      border-radius: 1.2rem;
    }
  }
  .video-track {
    overflow: hidden;
    border: 1px solid $gray-10;
    border-radius: 13px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: black;
    box-sizing: border-box;
  }
  .menu-icon {
    cursor: pointer;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    fill: white;
    height: 1.5rem;
    width: 3rem;
    padding: 0.5rem;
  }
  .participant-name {
    position: absolute;
    border-radius: 1.25em 1.25em 0 0;
    bottom: 0.1rem;
    left: 0;
    right: 0;
    height: 1.2em;
    padding: 0.3rem 3rem;
    line-height: 1.2;
    background: rgba(0, 0, 0, 0.68);
    color: white;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
    margin: auto;
    max-width: 60%;
    min-width: 40%;
  }
  .audio-level-indicator {
    position: absolute;
    bottom: 0.4rem;
    right: 1rem;
    background: $red-4;
    padding: 0.3rem 0.3rem 0 0.3rem;
    border-radius: 50%;
  }
}
