.audio-level-indicator {
  position: absolute;
  right: -48px;
  bottom: 10px;
  padding: 0 20px;
  align-self: flex-end;
}

.container {
  position: relative;
}