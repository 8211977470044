@import '@pixelcanvas/ui/dist/variables';

.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-basis: 100%;
}

.heading {
  margin-bottom: 4rem;
}

.fieldName {
  margin-bottom: 1.6rem;
}

.field {
  margin-bottom: 4rem;
}

.inputField {
  margin-bottom: 3.2rem;
}

.uploadContainer {
  display: flex;
}
.uploadRendered {
  display: flex;
  position: relative;
  width: 14.8rem;
  height: 14.8rem;
}
.dashedBorder {
  border: 2px dashed #7d6c86;
  border-radius: 50%;
  background: #27233c 0% 0% no-repeat padding-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 2px solid #707070;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}
.uploadActionsContainer {
  display: flex;
  flex-direction: column;
  margin-left: 4.7rem;
  justify-content: center;
  .uploadActions {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .uploadDescription {
    margin-top: 1.6rem;
    font-size: 1.4rem;
  }
  .fileInput {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}

.icon {
  width: 7.5rem;
  height: 6.1rem;
  color: #5a5574;
}
