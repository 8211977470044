@import '../../../styles/variables';

.content {
  width: 400px;
  color: white;

  a {
    padding: 10px;
  }

  a:link, a:visited {
    color: white;
  }

  a:hover {
    color: $purple-2;
  }
}
