@import '@pixelcanvas/ui/dist/variables.scss';

.modal {
  width: 98rem;
}

.addedModelsHeader {
  margin-bottom: 1.6rem;
}

.selection {
  padding-top: 3.2px;
  .controls {
    margin: 1.6rem 0 3.2rem;
    padding: 0;
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    .select {
      flex: 1;
    }
    .button {
      margin-left: 1.6rem;
    }
  }
}

.section {
  margin-bottom: 3.6rem;
  .header {
    margin-bottom: 1.6rem;
    font-size: 2rem;
    font-weight: 400;
    font-family: $font-family-omniya-default;
    color: $high-emphasis;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.eventInfo {
  margin-bottom: 2.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    width: 1.4rem;
    height: 1.4rem;

    path {
      fill: $high-emphasis;
    }
  }

  .info {
    margin: 0 2.4rem 0 0.8rem;
  }
}

.events {
  .heading {
    margin-bottom: 1.6rem;
  }
  .text {
    margin-bottom: 2.4rem;
  }
  :last-child {
    margin-bottom: 0;
  }
}

.actions {
  padding: 0 0.6rem 0 2.2rem;
}

.created {
  margin-right: auto;
}
