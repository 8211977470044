@import '@pixelcanvas/ui/dist/variables';

.avatar {
  box-sizing: border-box !important;
  border-radius: 50%;
  margin-right: 0.8rem;
  font-size: 1.6rem;
  height: 2em;
  width: 2em;
  color: $deactive;
  background-color: white;
  padding: 0.8rem 0.6rem;
}
