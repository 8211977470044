@import '../../../../../static/styles/mixins.scss';

.main {
  box-sizing: border-box;

  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;

  width: 100%;
  height: 100%;
  min-height: 0;

  padding: 1.6rem 11.6rem 1.6rem 1.6rem;
  
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  .container {
    max-height: 100%;
    max-width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    .controls {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;
      color: white;
      gap: 1.6rem;
      .button {
        background: none;
        border: none;
        cursor: pointer;
        color: inherit;
        font-size: 1.5em;
        &:hover {
          opacity: 0.7;
        }
        &:disabled {
          opacity: 0.2;
          cursor: default;
        }
      }
    }

    .image {
      width: auto;
      flex: 1 1 100%;
      min-height: 0;
      object-fit: contain;
    }
    .name {
      font-size: 1.5em;
      font-weight: 600;
      font-family: 'Roboto', Arial, Helvetica, sans-serif;
    }
    .description {
      height: 2.375em;
      @include multiline-ellipsis(2);
    }
    > * + * {
      margin-top: 1.6rem;
    }
  }

}
