@import '../../../../../static/styles/variables.scss';

.controls {
  display: flex;
  justify-content: space-evenly;
  min-width: 200px;

  svg {
    &:hover {
      opacity: 0.5;
    }
  }

  .microphone {
    width: 30px;
    height: 30px;
    padding: 10px;
    border-radius: 50%;
    background: #ffffff9c;
    box-shadow: 0 3px 6px #00000029;
    border: 1px solid #f8f9fb5c;

    path {
      fill: $berry;
    }
  }

  .video {
    width: 30px;
    height: 30px;
    padding: 10px;
    border-radius: 50%;
    background: #ffffff9c;
    box-shadow: 0 3px 6px #00000029;
    border: 1px solid #f8f9fb5c;

    path {
      fill: $berry;
    }
  }

  .presenter {
    width: 30px;
    height: 30px;
    padding: 10px;
    border-radius: 50%;
    background: #ffffff9c;
    box-shadow: 0 3px 6px #00000029;
    border: 1px solid #f8f9fb5c;

    path {
      fill: $berry;
    }
  }

  .presenter-mode-active {
    background: $purple-2;
  }

  .leave {
    width: 30px;
    height: 30px;
    padding: 10px;
    border-radius: 50%;
    background: $red-5;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid $red-6;

    path {
      fill: white;
    }
  }
}