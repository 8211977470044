@import '@pixelcanvas/ui/dist/variables';

.heading {
  margin-bottom: 3.2rem;
}

.billing.billing {
  margin-bottom: 3.2rem;
  line-height: normal;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.8rem;
  }

  .manage {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .button {
    margin-bottom: 1.6rem;
  }

  .note {
    text-align: right;
  }

  .support {
    color: $links;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    font-style: italic;
  }
}

.summary {
  > * + * {
    margin-top: 0.8rem;
  }
}

.plans {
  margin: 0 -1.5rem 3.2rem;
  --gutter-width: 1.6rem;

  .planCards {
    padding: 1.6rem 0 3.2rem;

    .features {
      margin-top: 3.2rem;
      line-height: normal;
      margin-bottom: 0;
    }
  }
}

.period {
  font-size: 0.75em;
}
