@import "../../../../static/styles/variables.scss";

.modal-content {
  width: 32vw;
  min-height: 25vh;
  max-height: 60vh;
}

.modal-header {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 2rem;
  margin-top: 1rem;
  color: $gray-11;
}

.modal-description {
  font-size: 1.4rem;
  line-height: 1.2;
  font-weight: 600;
  color: $gray-11;
}

.invite-input {
  width: calc(100% - 4rem);
  margin: 0.8rem 0;
  border: 0.05rem solid $gray-18;
  border-radius: 0.5rem;
  padding: 1rem;
  outline: none;
}

.item {
  padding: 1.5rem;
  cursor: pointer;
  &:hover {
    background: $gray-22;
  }
}

.invitee-chips {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  max-height: 15rem;
  overflow-y: auto;
}

.invitee-chip {
  padding: 0.4rem 1rem;
  margin: 0.5rem;
  height: 2rem;
  max-width: 20rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0.1rem solid $gray-11;
  border-radius: 1.5em;
}

.invitee-chip-name {
  padding-right: 1rem;
  flex: 1;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
}

.invitee-chip-remove {
  flex-shrink: 0;
  height: 1.6rem;
  width: 1.6rem;
  text-align: center;
  cursor: pointer;
}

.remove-icon {
  height: 1rem;
  width: 1rem;
  cursor: pointer;
}
