.page-background-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}

.page-header {
  color: white;
  font-size: 40px;
  font-weight: bold;
  padding: 20px 150px 20px 50px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5),
      rgba(55, 55, 55, 0));
  text-shadow: 0px 3px 6px #FFFFFF73;
}

.stage {
  margin: auto;
  padding: 0 5px;
  max-width: 1500px;

  .stage-contents {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: calc(100% - 125px);

    .content {
      flex: 1;
      height: 100%;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      margin-right: 20px;
      max-width: 1000px;
    }

    .chat {
      width: 370px;
      margin-left: 20px;
      background: white;
      border-radius: 20px;
      margin-bottom: 50px;
      height: 800px;
    }
  }
}