@import '../../../../../static/styles/mixins.scss';

$card-radius: calc(4em / 24);

.card {
  box-sizing: border-box;

  background: none;
  border: none;
  color: inherit;

  padding: 0;

  font-size: 2.4rem;

  cursor: pointer;

  width: 30rem;

  .span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: $card-radius;
    border: 2px solid grey;
  }
}

.thumbnail {
  background: none;

  box-sizing: border-box;

  border-radius: $card-radius $card-radius 0 0;

  background-color: rgba(white, 0.2);
  backdrop-filter: blur(0.4rem);

  width: 100%;
  height: 20rem;

  position: relative;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: $card-radius $card-radius 0 0;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: none;
    justify-content: center;
    align-items: center;

    background-color: rgb(#000, 0.5);

    font-size: 6.4rem;

    .icon {
      font-size: 6.4rem;
      opacity: 0.7;
    }
  }
}

.span:hover .overlay {
  display: flex;
}

.text {
  box-sizing: border-box;

  border-radius: 0 0 $card-radius $card-radius;
  background-color: rgba(#000000, 0.5);

  width: 100%;

  padding: 0.8rem;

  height: 8.4rem;

  text-align: left;

  display: flex;
  flex-direction: column;
}

.name {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
}

.description {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  max-height: 100%;
  max-width: 100%;

  @include multiline-ellipsis(2);
}
