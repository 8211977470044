.modal.modal {
  width: 98rem;

  .content {
    padding-bottom: 6.4rem;
  }
}

.link {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  width: 100%;

  margin-bottom: 4.8rem;
}

.url {
  flex: 1 1 auto;
}

.copy {
  margin-left: 0.8rem;
  .icon {
    margin-right: 0.8rem;;
  }
}

.details {
  margin-bottom: 1.6rem;
}

.status {
  display: flex;
  flex-direction: row;
  align-items: center;

  .label {
    margin-right: 0.8rem;
  }
  .tooltip {
    margin-right: 1.6rem;
  }
}

.status + .status {
  margin-top: 1.6rem;
}
