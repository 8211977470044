@import '../../../../static/styles/variables';

.container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  .info {
    text-transform: capitalize;
    margin: 0.2em 0;
    color: $gray-2;
  }
}
