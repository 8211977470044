@import '../../styles/variables';

.date-time {
  display: flex;
}

.datetime-input {
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid $gray-2;

  &:focus {
    outline: none;
    border: 1px solid $gray-2;
  }
}
