@import '@pixelcanvas/ui/dist/variables';
@import '../../styles/variables';

.cards {
  padding-top: 3.6rem;
  padding-bottom: 3.6rem;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 1.2rem;

  > :not(:last-child) {
    margin-bottom: 1.2rem;
  }

  @media screen and (min-width: $breakpoint-md) {
    flex-direction: row;
    align-items: center;

    > :first-child {
      margin-right: auto;
    }

    > :not(:first-child) {
      margin-left: 2rem;
    }

    > :not(:last-child) {
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    > :not(:first-child) {
      margin-left: 4rem;
    }
  }

  .sort {
    width: 30rem;
  }
}

.icon {
  width: 13px;
  height: 13px;
  margin-left: 5px;

  path {
    fill: white;
  }
}

.noEvents {
  color: white;
  font-size: 20px;
  font-weight: 600;
}
