@import "../../../../static/styles/variables";

.label {
  display: flex;
  flex-direction: column;
  color: black;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  &.required {
    color: $red-1;
  }
}
.inputContainer {
  position: relative;
  width: 100%;
}
.input {
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.26;
  background-color: white;
  border: 0.05rem solid $gray-18;
  border-radius: 0.5rem;
  margin-top: 1rem;
  padding: 1rem 2rem;
  outline: none;
  color: $gray-11;
  box-sizing: border-box;
  &::placeholder {
    color: $gray-1;
    font-style: italic;
  }
  &:focus {
    border: 0.05rem solid transparent;
    box-shadow: 0 0 0 0.2rem #686868a6;
  }
  &.required {
    border: 0.05rem solid transparent;
    box-shadow: 0 0 0 0.1rem solid $red-1;
  }
}
.footer {
  position: absolute;
  margin-top: 0.2rem;
  right: 0;

  display: flex;
  gap: 1rem;
  align-items: center;
  color: $red-1;
  font-style: italic;
  font-size: 1.6rem;
  .maxLength {
    font-size: 1.4rem;
    line-height: 1.2;
    color: $gray-19;
    text-align: right;
    margin-left: auto;
  }
  .error {
    width: 1.5rem;
    height: 1.5rem;
    path {
      fill: $red-1;
    }
  }
}
