@import "@pixelcanvas/ui/dist/variables.scss";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: $high-emphasis;
}

.progressbar-container {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

.icon {
  margin-bottom: 1.5rem;
}

.error {
  color: $red-2;
}

.time-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
}

.unit {
  font-style: italic;
}

.tooltip {
  border: 1px solid $purple-6;
}

.tooltip-icon {
  margin-left: 0.5rem;
}
