@import '@pixelcanvas/ui/dist/variables.scss';

.tab {
  box-sizing: border-box;
  margin: 0;
  border: 0;
  padding: 0;
  height: 1.33em;
  line-height: 1.33em;
  background-color: transparent;
  margin-right: 4.8rem;
  list-style: none;
  cursor: pointer;

  font-size: 1.8rem;
  font-weight: 400;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  color: $medium-emphasis;
  text-decoration: none;

  &:hover {
    color: $high-emphasis;
  }

  &.selected {
    font-weight: 700;
    color: $high-emphasis;
  }
}
