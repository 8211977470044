.chat-bubble-container {
  width: 100%;
  height: 100%;
  .tooltip-text {
    visibility: hidden;
    max-width: 80px;
    font-size: smaller;
    background-color: black;
    opacity: 0.7;
    color: #fff;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: -30px;
    left: 50%;
    transform: translate(-50%);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .badge {
    position: absolute;
    left: 0;
    top: 0;
    background: red;
    color: white;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    line-height: 14px;
    font-size: smaller;
    text-align: center;
  }
  .close {
    visibility: hidden;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    background: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      width: 80%;
      height: 80%;
    }
  }
  .bubble-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.chat-bubble-container:hover .tooltip-text {
  visibility: visible;
}

.chat-bubble-container:hover .close {
  visibility: visible;
}
