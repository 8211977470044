$font-primary: #797979;

$gray-1: #C4C4C4;
$gray-2: #DEDEDE;
$gray-3: #7C7C7C;
$gray-4: #EDEDED;
$gray-5: #4A4A4A;
$gray-6: #FAFAFA;
$gray-7: #BCB9C6;
$gray-8: #8B8B8B;
$gray-9: #CDCDCD;
$gray-10: #797979;
$gray-11: #353535;
$gray-12: #C9C9C9;
$gray-13: #919191;
$gray-14: #B3B3B3;
$gray-15: #FFFFFFAD;
$gray-16: #B0B0B0;
$gray-17: #FFFFFFB0;
$gray-18: #C6C6C6;
$gray-19: #616161;
$gray-20: #8E8E8E;
$gray-21: #686868a6;
$gray-22: #E5E5E5;
$gray-23: #828282;
$gray-24: #646464;
$gray-25: #979797;
$gray-26: #201f23;

$purple-1: #35115C;
$purple-2: #735DDD;
$purple-3: #2F2850;
$purple-4: #120D2C;
$purple-5: #E2BCFF;
$purple-6: #4e447b;
$purple-7: #4934a7;
$purple-8: #262143;
$purple-9: #8d2eda;
$purple-10: #574687;
$purple-11: #42357d;
$purple-12: #312A53;
$purple-13: #4D4282;
$purple-14: #3F366B;
$purple-15: #39315C;
$purple-16: #3A3455;
$purple-17: #2E274B;
$purple-18: #484169;
$purple-19: #595083;
$purple-20: #2B234F;
$purple-20: #735DDDB3;
$purple-21: #CDADE6;
$purple-22: #221C3B;
$purple-23: #9300D9;

$sapphire: #130183;
$berry: #5D0054;
$charcoal: #2A2E33;

$pink-1: #B94EEB;

$yellow-1: #B47D32;

$blue-1: #85B5D0;
$blue-2: #4174BA;
$blue-3: #006CD6;

$red-1: #E58080;
$red-2: #FF6464;
$red-3: #FF0000;
$red-4: #ff6464;
$red-5: #EC5353;
$red-6: #C22B2B;
$red-7: #EE4044;

$green-1: #29847E;
$green-2: #c3e87f;

$header-height: 70px;
$overview-header-height: 91px;
$sidebar-width: 321px;
$px-sidebar-width: 100px;
$px-sidepanel-width: 300px;
$event-content-side-margin: 40px;
$save-footer-height: 80px;
$px-sidebar-vertical-padding: 20px;

$fullscreen-button-size: 80px;

$modal-gradient: transparent linear-gradient(122deg, #3D3565 0%, #27204A 20%, #2D2552 75%, #382552 100%) 0% 0% no-repeat padding-box;

$primary-button-glow: #CC28D3;

$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1536px;
