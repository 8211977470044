@import '../../../../static/styles/variables';

.page-background-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}

.exhibit-hall-page {
  .page-header {
    color: white;
    font-size: 40px;
    font-weight: bold;
    padding: 20px 150px 20px 50px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5),
        rgba(55, 55, 55, 0));
    text-shadow: 0px 3px 6px #FFFFFF73;

    background-image: transparent linear-gradient(180deg, #040505 0%, #151515D6 100%) 0% 0% no-repeat padding-box;
    backdrop-filter: blur(6px);
    padding: 20px 150px 20px 50px;

    .exhibit-header-items {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .all-booths {
        position: absolute;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        cursor: pointer;
        margin-right: 32px;
        margin-top: 1em;
        height: 1em;

        .store-icon {
          width: 15px;
          height: 15px;
          margin-right: 8px;

          path {
            fill: white;
          }
        }

        .all-booths-text {
          color: white;
          font-size: 1em;
          font-style: italic;
          font-weight: normal;
          white-space: nowrap;
          width: min-content;
          margin: 0;

          @media (max-width: 1270px) {
            display: none;
          }
        }
      }

      .exhibit-header {
        display: flex;
        flex-direction: row;
        width: 854px;
        max-width: 80%;
        min-width: fit-content;
        justify-content: space-between;
        align-items: flex-start;

        .exhibit-title {
          display: flex;
          flex-direction: column;

          .exhibit-title-text {
            width: max-content;
            font-size: 40px;
            margin: 0 13px;
            text-shadow: 0px 3px 6px #FFFFFF73;
          }

          .exhibit-dots {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 9px;

            .exhibit-dot {
              width: 8px;
              height: 8px;
              margin: 0 4px;
              border-radius: 50%;
              background: $gray-1;

              &.selected {
                background: white;
                box-shadow: 0 0 10px white;
              }
            }
          }
        }

        .exhibit-arrow {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .exhibit-arrow-icon {
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
            cursor: pointer;
            padding: 10px;
            border-radius: 50%;
            background: $gray-12;
            border: 1px solid white;
            margin: 0;

            path {
              fill: black;
            }

            &:hover {
              background: white;
            }
          }

          .exhibit-arrow-text {
            font-size: 16px;
            font-weight: normal;
            margin: 0 13px;

            @media (max-width: 1270px) {
              display: none;
            }
          }
        }
      }
    }

    .exhibit-selected-header {
      text-shadow: 0px 3px 6px #ffffff73;
      padding: 0;
      margin: 0;
    }
  }
}

.exhibit-hall {
  height: 100%;
  margin: auto;
  max-width: 1500px;

  .exhibit-hall-overview {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .exhibit-cell {
      position: relative;
      width: 350px;
      min-width: 350px;
      height: 200px;
      border-radius: 20px;
      overflow: hidden;
      border: 2px solid #00000000;
      background: transparent linear-gradient(180deg, #00000000 63%, #000000CC 100%) 0% 0% no-repeat padding-box;
      cursor: pointer;

      &:hover {
        border: 2px solid white;
      }

      .exhibit-image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
      }

      .cell-title {
        position: absolute;
        bottom: 10px;
        left: 22px;
        font-size: 25px;
        font-weight: bold;
        color: white;
      }
    }
  }

  .exhibit-layout {
    display: flex;
    flex-direction: row;
    margin: 10px 125px 25px 25px;
    justify-content: center;

    .content {
      flex-grow: 1;
      height: 100%;
      background: white;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      margin-right: 20px;
      max-width: 1000px;
    }

    .cta-items {
      background: white;
      width: 250px;
      border-radius: 20px;
      padding: 20px;
      overflow-y: auto;

      .cta {
        margin-bottom: 20px;

        .cta-title {
          color: $gray-11;
          font-size: 20px;
          font-weight: bold;
        }

        .cta-description {
          font-size: 16px;
          margin: 5px 0;
        }
      }
    }
  }

  .carousel-container {
    width: calc(100% - 150px);
    margin: 0 25px;
  }
}