@import "../../../../../../static/styles/variables.scss";

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10.7rem;
  padding: 1.3rem 3.8rem;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid $gray-2;
  font-size: 2.4rem;
  line-height: 1.125;
  color: $gray-11;
  cursor: pointer;
  &:hover {
    background: transparent linear-gradient(90deg, #ffffff 0%, #ffffff99 100%)
      0% 0% no-repeat padding-box;
  }
}
