@import "../../../../static/styles/variables.scss";

.wide {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: 2.4rem;
  }
}

.small-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin: 0 2rem;
  }
  > :first-child {
    margin-left: auto;
  }
  > :last-child {
    margin-right: auto;
  }
}
