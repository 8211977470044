@import '../../../../../static/styles/variables';

.presenter-settings-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  .presenter-settings-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    background: white;
    color: $purple-9;
    width: fit-content;
    height: fit-content;
    padding: 40px;
    outline: none;
    border-radius: 5px;

    .close-button {
      position: absolute;
      width: 25px;
      height: 25px;
      top: 20px;
      right: 20px;
      cursor: pointer;

      path {
        fill: $gray-1;
      }
    }

    .text-container {
      font-size: 18px;

      .content {
        height: 90px;
        margin-top: 20px;
        color: black;
        font-size: 16px;
        font-weight: 400;

        .warning {
          .triangle-error {
            width: 15px;
            height: 15px;
            margin-left: 5px;

            path {
              fill: white;
            }
          }

          padding: 5px;
          background: $purple-2;
          border-radius: 4px;
          color:white;
        }

        .presenter-option {
          width: 80%;
          margin: auto;
          margin-top: 10px;
          display: flex;
          align-items: center;

          .checkbox {
            width: 20px;
            height: 20px;
            margin: 0 10px 0 0;
          }
        }
      }
    }

    .modal-controls {
      display: flex;
      margin-top: 40px;
      text-align: center;

      .present-button {
        margin-left: 20px;
        box-shadow: none !important;
      }

      .cancel-button {
        margin-left: auto;
      }
    }
  }
}