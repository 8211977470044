@import "../../../../static/styles/variables";

.event-landing-page {
  display: flex;
  flex-direction: row;
  height: 100vh;
  font-family: "Heebo", Arial, Helvetica, sans-serif;
}
.left-panel {
  display: flex;
  flex-direction: column;
  color: black;
  width: 100%;
  text-align: center;
  padding: 4rem 0 2.2rem;
  overflow-y: auto;
  align-items: center;
  justify-content: space-between;
  @media (min-width: $md) {
    width: 50rem;
  }
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $gray-1;
    border-radius: 5px;
    height: 3rem;
  }
  :first-child {
    margin-top: auto;
  }
}
.logo {
  margin: auto auto 3rem;
  width: 33rem;
  height: auto;
  object-fit: contain;
  @media screen and (max-height: 850px) {
    margin: auto auto 0;
  }
}
.countdown {
  font-size: 4.8rem;
  display: inline-flex;
}
.segment {
  margin: 0 1.5rem;
}
.digits {
  color: white;
  text-align: center;
  font-size: 5rem;
  line-height: 8.2rem;
}
.label {
  color: white;
  margin-top: 0;
  letter-spacing: 0;
  font-size: 1.8rem;
  line-height: 2.4rem;
}
.until {
  color: white;
  font-size: 2.4rem;
  text-align: center;
  font-weight: 300;
  margin-bottom: 3rem;
  @media screen and (max-height: 850px) {
    margin-bottom: 1rem;
  }
}
.name {
  color: white;
  margin-bottom: 3rem;
  font-family: "Prelia", "Roboto", Arial, Helvetica, sans-serif;
  font-size: 3.5rem;
  font-weight: 400;
  text-transform: uppercase;
  width: 90%;
  align-self: center;
  @media screen and (max-height: 850px) {
    margin-bottom: 1rem;
  }
}
.time {
  color: white;
  margin-bottom: 3rem;
  font-size: 2.4rem;
  letter-spacing: 0;
  @media screen and (max-height: 850px) {
    margin-bottom: 1rem;
  }
}
.welcome {
  color: white;
  margin-bottom: 3rem;
  font-size: 1.8rem;
  text-align: left;
  padding: 1rem;
  text-align: center;
  max-width: 40rem;
  @media screen and (max-height: 850px) {
    margin-bottom: 1rem;
  }
}
.buttons {
  display: flex;
  flex-direction: row;
  margin-bottom: auto;
  padding-bottom: 8rem;
  @media screen and (max-height: 850px) {
    padding-bottom: 1rem;
  }
  .disabled {
    pointer-events: none;
    background: $gray-4;
    color: $gray-1;
  }
}
.button {
  font-size: 1.9rem;
  @media screen and (max-height: 850px) {
    font-size: 1.6rem;
  }
}
.landing-button {
  margin: 1rem;
}
.socials {
  margin: 1.6rem 0;
  @media screen and (max-height: 850px) {
    margin: 0 0 1rem;
  }
}
.support {
  color: white;
  font-size: 1.8rem;
  line-height: 2.34rem;
  margin: 0 5rem 0;
}
.dark {
  color: black;
}
.right-image {
  flex: 1;
  position: relative;
  display: none;
  @media (min-width: $md) {
    display: block;
  }
}
.event-landing-bg {
  user-select: none;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}
.landing-footer {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}
.private-page {
  background-color: #3a3455;
}
