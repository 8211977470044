.inputs {
  margin-top: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  > * {
    width: 100%;
  }
  > * + * {
    margin-top: 4rem;
  }
}
