@import "../../../static/styles/variables.scss";

.outline1 {
  position: absolute;
  overflow: visible;
  border: 0.5px solid #FFFFFF;
  border-radius: 2em;

  top: -1.75px;
  right: -1.75px;
  bottom: -1.75px;
  left: -1.75px;
}

.outline2 {
  position: absolute;
  overflow: visible;
  border: 2px solid #D96893;
  opacity: 1;
  border-radius: 2em;

  top: -2.5px;
  right: -2.5px;
  bottom: -2.5px;
  left: -2.5px;
}

.button {
  position: relative;
  display: flex;
  box-sizing: border-box;
  font-size: 1.6rem;
  font-family: "Prelia", "Roboto", Arial, Helvetica, sans-serif;
  color: white;
  overflow: visible;

  padding: 0.6em 3em;
  width: fit-content;
  min-width: 10em;
  height: 3em;

  background-color: transparent;
  border-width: 0;

  background: transparent linear-gradient(102deg, #A11C3F 0%, #7042AD 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #fd9ec9c4, 0px 3px 6px #ff5858b7, inset 0px 3px 6px #ff494959;
  border: 3px solid #E37EAC;
  border-radius: 2em;

  align-items: center;
  justify-content: center;
  user-select: none;
  text-transform: capitalize;
  cursor: pointer;

  .text {
    font-size: inherit;
    text-shadow: 0px 2px 3px #FFFFFF63;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  &:hover {
    opacity: 0.65;
  }
  &:disabled {
    opacity: 0.8;
  }
}
