@import "../../../../static/styles/variables.scss";

.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  background: rgba($color: white, $alpha: 0.8);
  color: $gray-11;
  width: fit-content;
  height: fit-content;
  max-height: 80vh;
  max-width: 80vw;
  overflow-y: auto;
  padding: 6.4rem 3.2rem 3.2rem 4.8rem;
  outline: none;
  border-radius: 1.5rem;
  border: 0.1rem solid $gray-17;
  box-shadow: 0px 0.3rem 0.6rem #00000029;
  backdrop-filter: blur(36px);
}

.close {
  position: absolute;
  width: 2.1rem;
  height: 3.3rem;
  top: 2.8rem;
  right: 3.2rem;
  z-index: 1;
  cursor: pointer;
  path {
    fill: $gray-11;
  }
}
