@import "../../../../static/styles/variables.scss";

.title {
  color: $gray-11;
  font-size: 3rem;
  font-weight: 700;
  text-transform: capitalize;
  margin-right: 3rem;
}

.subtext {
  color: $gray-11;
  font-size: 2rem;
  margin-top: 1rem;
}

.instructions {
  color: $gray-11;
  margin-top: 3rem;
  font-size: 1.8rem;
}

.actions-container {
  margin-top: auto;
}

.modal {
  min-height: 25vh;
  min-width: 32vw;
}
