@import '../../../../static/styles/variables';

.faq-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  .faq-modal {
    background: white;
    box-shadow: 0 3px 6px #2A2E3329;
    border: 1px solid #2A2E3329;
    border-radius: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100% - 160px);
    width: 750px;
    outline: none;

    .close-button {
      position: absolute;
      width: 30px;
      height: 30px;
      top: 24px;
      right: 24px;
      cursor: pointer;

      path {
        fill: #C7C7C7;
      }
    }

    .modal-heading {
      height: 120px;
      box-shadow: 0 3px 8px #00000029;
      padding: 50px 20px 30px;

      .modal-header {
        color: 'black';
        font-size: 1.3em;
        font-weight: bolder;
        height: 40px;
        width: 100%;
        text-align: center;
      }

      .modal-subheader {
        color: $charcoal;
        font-size: 0.8em;
        text-align: center;
        width: 100%;

        a {
          text-decoration: none;
          color: 'black';
        }
      }
    }

    .questions-container {
      overflow-y: auto;
      height: 100%;
      padding: 0 80px;

      .question-header {
        background: none;
        cursor: pointer;
        height: 60px;
        min-width: 100%;
        border-top: 1px solid #C7C7C7;
        display: flex;
        flex-direction: row;
        align-items: center;

        .question-text {
          line-height: 60px;
          color: $charcoal;
          flex: 1;
        }

        .expand-button {
          line-height: 60px;
          vertical-align: middle;
          fill: white;
          width: 12px;
          height: 12px;
          padding: 4px;
          border-radius: 50%;
          background: 'black';
        }
      }

      .question-header:hover,
      .expanded {
        .question-text {
          color: 'black';
        }
      }

      .question-header:hover:not(.expanded) {
        .expand-button {
          opacity: 0.5;
        }
      }

      .expanded {
        .expand-button {
          background: #FE5000;
        }
      }

      .answer {
        display: flex;
        flex-direction: row;

        .answer-image {
          max-width: 300px;
          max-height: 300px;
          margin: 20px;
          margin-left: 0;
          object-fit: contain;
        }

        .answer-text {
          flex: 1;
          max-width: fit-content;

          .answer-header {
            color: 'black';
            margin: 20px;
            margin-left: 0;
          }

          .answer-details {
            word-break: break-word;
            white-space: pre-wrap;
            margin: 20px;
            margin-left: 0;
            color: $charcoal;
          }
        }
      }
    }
  }

  @media screen and (max-height: 600px) {
    .faq-modal {
      height: calc(100% - 30px);
    }
  }

  @media screen and (max-width: 730px) {
    .faq-modal {
      width: 100%;

      .questions-container {
        padding: 0 20px;

        .answer {
          display: block;
        }

        .question-header {
          .question-text {
            line-height: 30px;
          }
        }
      }
    }
  }
}