@import "../../../../static/styles/variables";

.domain-whitelist-container {
  overflow-y: auto;
  padding: 3.2rem 0;
  color: white;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  height: calc(100% - 17.2rem);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .page-description {
    margin: 0;
    margin-bottom: 2.3rem;
    max-width: 118rem;
    line-height: 1.375;
  }

  .header-controls {
    display: flex;
    flex-direction: column;
    color: white;
    padding: 0;
    align-items: center;
    margin-bottom: 1rem;
    @media (min-width: $lg) {
      flex-direction: row;
      margin-bottom: 2.2rem;
    }

    .search {
      margin: 0 auto 1.2rem 0;
      @media (min-width: $lg) {
        margin: 0 auto 0 0;
      }
    }

    .secondary-controls {
      display: flex;
      flex-direction: row;
      align-self: flex-end;
      gap: 0.8rem;
      @media (min-width: $lg) {
        align-self: center;
      }

      .action-icon {
        width: 1em;
        height: 1em;
        margin-right: 0.8em;
        path {
          fill: white;
        }
      }
    }
  }

  .domain-whitelist-content-header-section {
    margin-bottom: 1.1rem;

    .domain-whitelist-content-header {
      display: flex;
      flex-direction: column;
      @media (min-width: $lg) {
        flex-direction: row;
      }
      align-items: first baseline;

      h2 {
        margin: 0;
        margin-right: 2rem;
        font-size: 2rem;
        font-weight: normal;
      }

      p {
        margin: 0;
        font-size: 1.6rem;
        font-style: italic;
      }
    }
  }
}
