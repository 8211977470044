@import '../../styles/variables';

.content-details {
  display: flex;
  flex-direction: column;
  color: white;
  background: transparent linear-gradient(122deg, #3d3565 0%, #27204a 20%, #2d2552 75%, #382552 100%) 0% 0% no-repeat padding-box;
  width: 80%;
  padding: 3rem 5rem;
  max-height: calc(100% - 6rem);
  overflow-y: auto;

  .upload-container {
    display: flex;
    margin: 1rem 0;

    .logo-preview {
      width: 15rem;
      height: 8rem;
      object-fit: contain;
    }

    .upload-button-container {
      display: flex;
      flex-direction: column;
      margin-left: 2rem;
      justify-content: center;
    }
  }

  .page-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  .checkbox-field {
    margin: 2rem 0;

    .checkbox-label {
      margin-bottom: 1rem;
      color: $purple-5;
      font-size: 1.4rem;
      font-weight: 600;
    }
  }

  .detail-label {
    margin: 1rem 0 0 0;
    color: $purple-5;
    font-size: 1.4rem;
    font-weight: 600;
  }

  .date-time {
    margin-bottom: 1rem;
  }

  .required-error {
    color: $red-1;

    .triangle-error {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 1rem;

      path {
        fill: $red-1;
      }
    }
  }

  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem 0;
    column-gap: 2.4rem;

    .add-another {
      margin-right: auto;
    }

    .cancel {
      margin-left: 1rem;
    }
  }
}