@import "../../static/styles/variables";

.header {
  display: flex;
  height: $header-height;
  background: transparent linear-gradient(360deg, #66177100 0%, #60158e 100%) 0% 0% no-repeat padding-box;
  padding: 15px 0;
  color: white;
  align-items: center;
  user-select: none;
  &.isAuthenticated {
    background: $purple-3;
    padding: 0;
  }
  .brandLogo {
    margin-left: 20px;
    height: 48px;
    object-fit: contain;
    margin-right: auto;
  }
  .brandLogoText {
    margin-left: 20px;
    height: 48px;
    object-fit: contain;
    margin-right: auto;
  }
  .contactButton {
    color: white;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
  }
  .loginButton {
    margin: 0 40px;
    font-weight: 600;
    padding: 5px 40px;
  }
  .headerUser {
    position: relative;
    display: flex;
    margin: 0 40px 0 auto;
    align-items: center;
  }
}
