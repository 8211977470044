@import "../../../../../static/styles/variables.scss";

.button {
  width: 44rem;
  height: 8rem;
  padding: 0;
  background: rgba(255, 255, 255, 0.65);
  border-radius: 8rem;
  box-shadow: 0px 3px 6px #00000029;
  border: 0;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 2.4rem;
  color: black;
  line-height: 1.4;
  position: relative;
  cursor: pointer;
  &:hover {
    color: rgba(0, 0, 0, 0.65);
    .icon {
      opacity: 0.65;
    }
  }
  &:disabled {
    color: rgba(0, 0, 0, 0.4);
    cursor: auto;
    .icon {
      opacity: 0.4;
    }
  }
}

.span {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 2rem;
  margin: 0;
}

.icon {
  position: absolute;
  left: 3.2rem;
  height: 4rem;
  top: 50%;
  transform: translateY(-50%);
}
