@import '../../../../../static/styles/variables.scss';

.video-devices {
  display: flex;
  flex-direction: column;
  .video-track {
    height: 15rem;
    margin-top: 1rem;
    margin-right: auto;
    border-radius: 1.3rem;
    border: 1px solid $gray-23;
  }
}
