@import "../../../../static/styles/variables.scss";

.button {
  display: flex;
  padding: 0.6em 1em;
  width: fit-content;
  min-width: 10em;
  height: fit-content;
  font-size: inherit;
  line-height: 1.3;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-origin: padding-box;
  border-radius: 2em;
  align-items: center;
  justify-content: center;
  user-select: none;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    opacity: 0.65;
  }
  &:disabled {
    opacity: 0.8;
  }
}

.primary {
  color: white;
  background-color: $purple-23;
  border: 1px solid transparent;
}

.secondary {
  color: white;
  background: $purple-2;
  border: 1px solid transparent;
}

.tertiary {
  color: $gray-11;
  background-color: transparent;
  border: 1px solid $gray-11;
}

.md {
  font-size: 1.6rem;
}

.sm {
  font-size: 1.2rem;
}
