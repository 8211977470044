@import "../../../../static/styles/variables";
@import "@pixelcanvas/ui/dist/variables.scss";

.tooltip {
  display: flex;
  flex-direction: row;
  position: relative;
  .icon {
    color: $high-emphasis;
    width: 1.8rem;
    height: 1.8rem;
  }
  .card {
    position: absolute;
    left: 1rem;
    z-index: 2;
    width: 32.2rem;
    border: 1px solid #594c96;
    border-radius: 0.8rem;
    display: flex;
    flex-direction: column;
    background: $purple-6;
    color: white;
    white-space: normal;
    &.bottom {
      top: 0;
    }
    &.top {
      bottom: 0;
    }
    .card-top {
      display: flex;
      .image {
        object-fit: contain;
        width: 100%;
        border-radius: 0.8rem 0.8rem 0 0;
      }
    }
    .card-bottom {
      border-radius: 0 0 0.8rem 0.8rem;
      .title {
        margin: 1rem;
      }
      .description {
        margin: 1rem;
        line-height: 2.5rem;
        text-align: left;
      }
    }
  }
}
