@import '../../styles/variables.scss';

.detail-label {
  display: flex;
  flex-direction: column;
  color: $purple-5;
  font-size: 14px;
  font-weight: 600;
  &.required {
    color: $red-1;
  }
}

.input-container {
  width: 100%;
  .text-input {
      width: 100%;
      font-size: 14px;
      background: none;
      border: none;
      border-bottom: 1px solid $purple-5;
      margin: 10px 0 5px 0;
      padding: 10px 0;
      outline: none;
      color: white;
      &::placeholder {
          color: $gray-1;
          font-style: italic;
      }
      &:focus {
          border-bottom: 2px solid #85B5D0;
      }
      &.required {
        border-bottom: 1px solid $red-1;
      }
  }
}

.rdw-editor-wrapper {
  background: white;
  color: black;
}

.rdw-editor-toolbar {
  visibility: unset !important;
}

.wysiwyg-container {
  margin-top: 10px;
}
