@import '../../styles/variables.scss';

.profile-signup {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  user-select: none;

  .profile-title {
    margin-top: 1rem;
    font-size: 3.6rem;
  }

  .details-container {
    >*+* {
      margin-top: 2rem;
    }

    .profile-description {
      // margin: 1rem rem;
    }

    .profile-picture-section {
      display: flex;

      // margin: 5rem 0;
      .profile-picture-container {
        display: flex;
        flex-direction: column;
        font-size: 1.4rem;
        font-weight: 600;
        color: $purple-5;

        .profile-picture-img {
          margin-top: 1rem;
          width: 10rem;
          height: 10rem;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .upload-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 1rem;

        .photo-limit {
          margin-top: 2rem;
          font-style: italic;
        }

        .file-input {
          visibility: hidden;
          width: 0;
          height: 0;
        }
      }
    }

    .email-help {
      font-size: 14px;
      color: $gray-1;
      font-style: italic;
      margin-top: 0.8rem;
      margin-bottom: 3.2rem;
    }
  }
}