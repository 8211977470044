@import './../../../../static/styles/variables';

.blurred-background {
  position: absolute;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  width: 100%;
  height: 100%;

  .blurred-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.up-next {
  color: white;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-shadow: 0px 3px 6px #26262673;

  .thumbnail {
    margin-left: 30px;
    margin-right: 0;
    max-width: 40%;
  }

  .info-block {
    width: 60%;
    padding-left: 30px;
    font-size: 14px;

    .time {
      text-transform: uppercase;

    }

    .up-next-title {
      padding-top: 10px;
      padding-bottom: 20px;
      font-weight: bold;
      font-size: 18px;
    }
  }

  .no-livestream {
    margin: auto;
    font-size: 18px;
  }
}