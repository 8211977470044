@import '../../../../static/styles/variables.scss';

.title {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 2rem;
  margin-top: 1rem;
  color: $gray-11;
}
