@import '@pixelcanvas/ui/dist/variables';

.content {
  height: fit-content;
  padding-bottom: 6.4rem;
}

.fields {
  margin-top: 3.2rem;
  > * + * {
    margin-top: 0.8rem;
  }
}

.contact {
  margin-top: -0.8rem;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 3.2rem;
}

.icon {
  margin-right: 0.8rem;
}

.showcasecontent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1.6rem;
  .text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    > * + * {
      margin-top: 1.6rem;
    }
  }
  .thumbnail {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.8rem;
    img {
      height: 6.4rem;
    }
  }
  .video, .image {
    height: 16rem;
  }
  .link {
    align-self: center;
    font-size: 1.6rem;
    color: $high-emphasis;
    white-space: nowrap;
    .icon {
      margin-left: 0.5em;
    }
  }
}

.tabactions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1.6rem;
  width: 100%;
}

.tabname {
  width: 100%;
}
