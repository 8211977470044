.heading {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 0.8rem;
  > * {
    margin-right: 0.8rem;
  }
}

.metrics {
  > :last-child {
    margin-left: auto;
  }
}

.table {
  margin-bottom: 3.2rem;
}
