@import '@pixelcanvas/ui/dist/variables.scss';

.background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  background-color: #282c3a;
}

.container {
  height: 100%;
  padding-right: 10rem;

  display: flex;
  flex-direction: column;
}

.head {
  width: 100%;
  background-color: #00000047;
  backdrop-filter: blur(4.8rem);
  flex: 0 0 24rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  flex: 0 0 auto;
  padding-left: 6.4rem;
  padding-right: 3.2rem;
  padding-top: 3.2rem;
}

.actions {
  margin-left: -4rem;
  margin-top: -0.8rem;
  margin-bottom: 2.4rem;
}

.title {
  color: white;
  font-size: 4rem;
  font-family: 'Prelia', 'Open Sans', sans-serif;
  font-weight: 400;
  text-shadow: 0 0.3rem 3rem #00000029;
  margin-top: 0;

  margin-bottom: 0.4rem;
  @media screen and (min-width: $breakpoint-lg) {
    margin-bottom: 2.4rem;
  }
}

.descriptions {
  margin-bottom: 2.4rem;
}

.description {
  color: #FCFDFF;
  font-size: 1.8rem;
  font-family: 'Open Sans', sans-serif;
  text-shadow: -1rem 0 3rem #000000D9;
  text-overflow: ellipsis;

  white-space: nowrap;
  overflow: hidden;
  @media screen and (min-width: $breakpoint-md) {
    white-space: normal;
    overflow: visible;
  }

  line-height: normal;
  @media screen and (min-width: $breakpoint-lg) {
    line-height: 1.67;
  }
}

.nav {
  width: 100%;
  height: 4.8rem;
  background-image:
    linear-gradient(90deg, #FFFFFF00 0%, #FFFFFF59 17%, #FFFFFF59 84%, #FFFFFF00 100%),
    linear-gradient(90deg, #FFFFFF00 0%, #FFFFFF59 17%, #FFFFFF59 84%, #FFFFFF00 100%);
  background-size: 100% 1px;
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
  padding-left: 6.4rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin-right: 2.4rem;
  }
}

.navButton {
  font-size: 1.8rem;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  padding: 0;
  border: 0;
  background: none;

  color: #FFFFFF;
  cursor: pointer;
  &.disabled {
    color: #8C8C8C;
    cursor: inherit;
    .navIcon path {
      fill: #8C8C8C;
    }
  }
}

.navButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  background: none;
}

.navIcon {
  height: 1em;
  width: 1em;
  path {
    fill: white;
  }
}

.pageNumbers {
  padding: 0 1.6rem;

  > * {
    margin-right: 1.6rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

.pageNumber {
  box-sizing: border-box;
  font-size: 1.6rem;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  color: #FFFFFF;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;

  &.current {
    color: #FCFDFF;
    text-shadow: 0 0 1rem #FFFFFFDE;
    cursor: auto;
  }
}

.content {
  flex: 1 1 0;

  min-height: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-bottom: 2.4rem;

  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.mapView {
  margin: auto;
}
