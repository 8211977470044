@import 'static/styles/variables';

.pixel-platform {
    padding: 0 !important;
    .platform-button {
        display: flex;
        color: white;
        padding: 7px 15px;
        width: fit-content;
        min-width: 100px;
        height: fit-content;
        font-weight: 600;
        background: transparent linear-gradient(101deg, #CC28D3 0%, #9300D9 100%) 0% 0% no-repeat padding-box;
        border-radius: 20px;
        align-items: center;
        user-select: none;
        justify-content: center;
        cursor: pointer;
        border: 1px solid #00000000;
        -webkit-box-shadow:0 0 20px $primary-button-glow;
        -moz-box-shadow: 0 0 20px $primary-button-glow;
        box-shadow:0 0 20px $primary-button-glow;
        &:hover {
            border: 1px solid white;
        }
    }
    .platform-button-secondary {
        display: flex;
        color: white;
        padding: 0.4em 1em;
        width: fit-content;
        min-width: 5em;
        height: fit-content;
        font-weight: 600;
        background: $purple-2;
        border-radius: 0.5em;
        align-items: center;
        user-select: none;
        justify-content: center;
        cursor: pointer;
        border: 1px solid transparent;
        &:hover {
            border: 1px solid white;
        }
    }
    .platform-button-secondary-inverted {
        display: flex;
        color: $purple-9;
        padding: 7px 15px;
        width: fit-content;
        min-width: 80px;
        height: fit-content;
        font-weight: 600;
        background: white;
        border-radius: 20px;
        align-items: center;
        user-select: none;
        justify-content: center;
        cursor: pointer;
        border: 1px solid #00000000;
        &:hover {
            border: 1px solid white;
        }
    }
    .platform-button-tertiary {
        color: white;
        height: fit-content;
        padding: 7px 15px;
        width: fit-content;
        min-width: 80px;
        text-align: center;
        border: 1px solid white;
        border-radius: 20px;
        user-select: none;
        cursor: pointer;
        &:hover {
            background: white;
            color: black;
        }
    }
    .platform-spinner {
        animation-name: platform-spinner-animation;
        animation-duration: 1s;
        animation-iteration-count: infinite;
    }
    @keyframes platform-spinner-animation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
