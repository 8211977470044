@import '@pixelcanvas/ui/dist/variables';

.pill {
  height: 2.4rem;
  width: fit-content;
  border-radius: 2.4rem;
  color: white;

  font-size: 14px;
  font-weight: 600;

  display: flex;
  align-items: center;
  padding: 0 1.2rem 0 0.8rem;

  .logo {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }

  .label {
    line-height: normal;
  }

  &.DRAFT {
    background-color: $low-emphasis;
  }

  &.LIVE {
    background-color: $error;
  }
}
