@import "@pixelcanvas/ui/dist/variables.scss";

.header {
  .logo {
    max-width: 14rem;
  }

  .organization {
    margin-right: auto;
  }

  .divider {
    border-left: 1px solid $border-1;
    height: 80%;
  }
}
