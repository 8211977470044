@import "../../../../static/styles/variables.scss";

.create-channel-panel {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .panel-heading {
    font-size: 32px;
    padding: 15px;
    background: rgba(223, 223, 224, 0.1);
    .panel-subtext {
      font-size: 1.4rem;
      color: #4b4b4b;
      padding: 1em 0 0;
      height: 3em;
      font-weight: normal;
    }
  }
  .form-items {
    flex: 1;
    display: flex;
    flex-direction: column;
    .checkbox {
      border: 0.5px solid $gray-25;
    }
    .radiobutton {
      border: 0.5px solid $gray-25;
    }

    .section-heading {
      margin: 1rem auto 1rem 2rem;
      font-weight: 500;
      font-size: 1.5rem;
    }
    .privacy-settings {
      display: flex;
      flex-direction: column;
      margin-left: 2rem;
    }
    .capabilities-settings {
      display: flex;
      margin-left: 2rem;
    }
    .choice-label {
      margin-left: 1rem;
    }
    .input-field {
      margin: 0 1em 1em 1.5em;
      background: #ffffffe6 0% 0% no-repeat padding-box;
      outline: none;
      border: 1px solid #b9b9b9b0;
      border-radius: 9px;
      padding: 10px;
    }
    .channel-description {
      height: 80px;
      resize: none;
    }
    .channel-invite {
      width: calc(100% - 4em);
      margin: 0 1em 1em 1.4em;
      background: #ffffffe6 0% 0% no-repeat padding-box;
      border: 1px solid #b9b9b9b0;
      border-radius: 9px;
      padding: 10px;
      outline: none;
    }
    .item {
      padding: 5px;
      cursor: pointer;
      &:hover {
        background: #c4c4c4;
      }
    }
    .invitee-chips {
      display: flex;
      flex-wrap: wrap;
      max-height: 150px;
      overflow-y: auto;
      .invitee-chip {
        border: 1px solid #2a2e33;
        border-radius: 1.4em;
        padding: 0.2em 1em;
        margin: 0 0 1rem 2rem;
        height: 20px;
        max-width: 200px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .name {
          padding-right: 10px;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          letter-spacing: 0px;
          color: $charcoal;
          font-size: 12px;
          font-weight: 600;
        }
        .remove {
          flex-shrink: 0;
          height: 16px;
          width: 16px;
          text-align: center;
          cursor: pointer;
          .remove-icon {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
    .create-channel-button {
      cursor: pointer;
      flex-shrink: 0;
      background-color: $gray-26;
      border-radius: 2.2em;
      border: none;
      padding: 0.6em 2.2em;
      text-align: center;
      text-decoration: none;
      margin: auto auto 36px;
      font-size: 17px;
      font-weight: bold;
      color: #f6fbff;
    }
    .create-channel-button:disabled {
      background-color: gray;
      cursor: default;
    }
  }
}
