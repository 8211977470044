@import "../../../../static/styles/variables";

.chat-window {
  position: absolute;
  right: 0;
  bottom: -20px;
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0px 0.3rem 0.6rem #00000029;
  backdrop-filter: blur(0.4rem);
  -webkit-backdrop-filter: blur(0.4rem);
  border-radius: 1rem 1rem 0 0;
  border: 1px solid white;
  max-height: 75rem;
  width: 40rem;
  display: flex;
  flex-direction: column;
  .header {
    border-bottom: 1px solid $gray-9;
    height: 7.5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    background: rgba(255, 255, 255, 0.56) !important;
    box-shadow: 0px 0.3rem 0.6rem #00000029;
    border-radius: 1rem 1rem 0 0;
    color: $gray-11;
    backdrop-filter: blur(2rem);
    -webkit-backdrop-filter: blur(2rem);
    align-items: center;
    .user-friends-icon {
      min-width: 25px;
      min-height: 25px;
      padding: 5px;
      width: 35px;
      height: 35px;
      margin-left: 10px;
      border-radius: 50%;
    }
    .channelIcon {
      width: 4rem;
      height: 4rem;
      margin-left: 1rem;
      border-radius: 50%;
      object-fit: cover;
    }
    .title {
      padding: 0 1rem;
      width: 50%;
      font-weight: 600;
      flex-grow: 1;
      height: 4.4rem;
      line-height: 2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      /* Chrome, */
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      display: inline-block;
      /* FF */
      -moz-box-orient: vertical;
      -moz-line-clamp: 2;
      display: -moz-box;
    }
    .buttons {
      display: flex;
      fill: gray;
      height: 100%;
      padding-top: 3rem;
      .action-button {
        height: 2.5rem;
        width: 2.5rem;
        margin-right: 1rem;
        cursor: pointer;

        path {
          fill: $berry;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
  .user-actions {
    backdrop-filter: blur(3rem);
    -webkit-backdrop-filter: blur(3rem);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-height: 15rem;
    .user-action {
      font-size: 1.2rem;
      margin: 0.4rem;
      padding: 0.6rem;
      border-radius: 0.2rem;
      background: lightblue;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-end;
      .user-action-text {
        max-width: 90%;
      }
      .user-action-dismiss {
        height: 1.5rem;
        width: 1.5rem;
        margin-left: auto;
        align-self: center;
        cursor: pointer;
      }
    }
  }
  .messages {
    backdrop-filter: blur(3rem);
    -webkit-backdrop-filter: blur(3rem);
    overflow-y: auto;
    background-color: $gray-4;
    opacity: 0.85;
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
    height: 36.5rem;
    padding: 0 0.8rem;
  }
  .input-container {
    backdrop-filter: blur(3rem);
    -webkit-backdrop-filter: blur(3rem);
    display: flex;
    flex-direction: row;
    height: 4rem;
    min-height: 4rem;
    padding: 0 1.5rem 1.5rem;
    height: 60px;
    min-height: 60px;
    background-color: $gray-4;
    .chat-input {
      display: flex;
      border: 0.1rem solid $gray-1;
      border-radius: 27px;
      background: white 0% 0% no-repeat padding-box;
      margin: auto 0;
      height: 3rem;
      flex-grow: 1;
      padding: 0.5rem 1.5rem;
      &:focus {
        outline: none;
      }
    }
    .send-arrow {
      margin: auto 0 auto 1rem;
      cursor: pointer;
      border-radius: 50%;
      width: 2.2rem;
      height: 2.2rem;
      padding: 9px;
      background-color: $gray-11;
      path {
        fill: white;
      }
      &:hover {
        opacity: 0.5;
      }
    }
  }
}
