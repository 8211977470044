@import "../../static/styles/variables.scss";

.detail-label {
  display: flex;
  flex-direction: column;
  color: $purple-5;
  font-size: 14px;
  font-weight: 600;
  &.required {
    color: $red-1;
  }
}

.input-container {
  width: 100%;
}

.text-input {
  width: 100%;
  font-size: 14px;
  background: none;
  border: none;
  border-bottom: 1px solid $purple-5;
  margin: 10px 0 5px 0;
  padding: 10px 0;
  outline: none;
  color: white;
  &::placeholder {
    color: $gray-1;
    font-style: italic;
  }
  &:focus {
    border-bottom: 2px solid #85b5d0;
  }
  &.required {
    border-bottom: 1px solid $red-1;
  }
}

.text-area {
  width: 100%;
  font-size: 14px;
  background: none;
  border: none;
  border-bottom: 1px solid $purple-5;
  margin: 10px 0 5px 0;
  padding: 10px 0;
  outline: none;
  color: white;
  overflow: hidden;
  resize: none;
  &::placeholder {
    color: $gray-1;
    font-style: italic;
  }
  &:focus {
    border-bottom: 2px solid #85b5d0;
  }
  &.required {
    border-bottom: 1px solid $red-1;
  }
}

.input-footer {
  display: flex;
  align-items: center;
  color: $red-1;
  font-style: italic;
  font-size: 16px;
  position: relative;
  bottom: 3rem;
}

.max-length {
  font-size: 14px;
  color: $gray-19;
  text-align: right;
  margin-left: auto;
}

.triangle-error {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  path {
    fill: $red-1;
  }
}

.helper-text {
  font-size: 14px;
  color: white;
  margin-top: 0;
  font-style: italic;
}
