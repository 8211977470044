@import "../../static/styles/variables";

.modal {
  width: 60rem;
}

.invalid-address {
  font-size: 14px;
  color: $red-1;
}

.triangle-error {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  path {
    fill: $red-1;
  }
}

.content {
  display: flex;
  flex-wrap: wrap;
  max-height: 250px;
  overflow-y: auto;
}

.email-chip {
  border-radius: 20px;
  border: 1px solid $purple-5;
  padding: 0.7rem;
  margin: 0.5rem;
  height: 2rem;
  max-width: 30rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 0.3rem;
}

.email {
  padding-right: 1rem;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}

.remove-icon {
  flex-shrink: 0;
  height: 16px;
  width: 16px;
  text-align: center;
  cursor: pointer;
  path {
    fill: $purple-5;
  }
}
