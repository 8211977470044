.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: none;
  justify-content: center;
  align-items: center;

  background-color: rgb(0 0 0 / 0.5);
  .icon {
    font-size: 6.4rem;
  }
}

.span:hover .overlay {
  display: flex;
}