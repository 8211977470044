@import '@pixelcanvas/ui/dist/variables.scss';

.pill {
  border: 1px solid $high-emphasis;
  border-radius: 1.6rem;
  padding: 0.7rem 1.6rem;
  width: fit-content;
}

.tr {
  &:hover {
    * {
      .action {
        visibility: visible;
      }
    }
  }
}

.action {
  visibility: hidden;
}

.header {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  user-select: none;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  column-gap: 0.8rem;
}

.container {
  overflow: auto;
  height: 100%;
  &::-webkit-scrollbar-thumb {
    background-color: $gray-2;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  -ms-overflow-style: auto;
  scrollbar-color: $gray-2 transparent;
  scrollbar-width: thin;
  &.scroll {
    overflow: auto;
  }
}

.sortIcon {
  color: $deactive;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 1.4rem;
}

.enabled {
  color: $medium-emphasis;
}

.icon {
  position: absolute;
  top: 0.2rem;
}
