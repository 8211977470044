@import "../../../../../static/styles/variables.scss";

.contact-popover {
  display: flex;
  padding: 2rem 1rem;
  margin-left: -2rem;
  width: 30rem;
  min-height: 12rem;
  background-color: rgba(white, 0.96);
  box-shadow: 0px 0.3rem 0.6rem $gray-11;
  border: 0.1rem solid $gray-17;
  border-radius: 0.7rem;
  backdrop-filter: blur(3.6rem);
}

.avatar {
  width: 9rem;
  height: 9rem;
  margin-right: 1rem;
  margin-left: 1rem;
  img {
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
    object-fit: cover;
  }
}

.details {
  max-width: 18rem;
  margin-left: 1rem;
}

.name {
  margin: 0;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.5;
  color: $charcoal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contactField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.companyContactField {
  margin-top: 0.8rem;
}

.label {
  color: $gray-19;
  font-size: 1.2rem;
  line-height: 2;
  text-transform: uppercase;
}

.company {
  line-height: 1.2;
  font-size: 1.4rem;
  margin: 0;
  padding: 0;
  display: flex;
  color: $gray-11;
}
