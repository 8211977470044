@import '../../../../static/styles/variables.scss';

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: $gray-2;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  font-size: 6.4rem;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}
