@import "@pixelcanvas/ui/dist/variables.scss";

.content > * + * {
  margin-top: 3.2rem;
}

.actions {
  display: flex;
  flex-direction: row;

  > * + * {
    margin-left: 4rem;
  }
  > :first-child {
    margin-right: auto;
  }
  .icon {
    margin-right: 0.8rem;
  }
}

.fields {
  margin-top: 3.2rem;
  > * + * {
    margin-top: 0.8rem;
  }
}

.contact {
  margin-top: -0.8rem;
}

.tooltip {
  background: black;
  padding: 1rem 0.8rem;
  border-radius: 0.4rem;
  border: 1px solid $border-1;
  box-shadow: 0 0 1.5rem #000000ad;
  z-index: 2000;
}

.arrow,
.arrow::before {
  position: absolute;
  width: 0.8rem;
  height: 0.8rem;
  z-index: -1;
}

.arrow::before {
  content: "";
  transform: rotate(45deg);
  background: black;
  border: 1px solid $border-1;
}

.tooltip[data-popper-placement^="top"] > .arrow {
  bottom: -0.5rem;
}
.tooltip[data-popper-placement^="top"] > .arrow::before {
  border-top: none;
  border-left: none;
}

.tooltip[data-popper-placement^="bottom"] > .arrow {
  top: -0.5rem;
}
.tooltip[data-popper-placement^="bottom"] > .arrow::before {
  border-bottom: none;
  border-right: none;
}

.tooltip[data-popper-placement^="left"] > .arrow {
  right: -0.5rem;
}

.tooltip[data-popper-placement^="left"] > .arrow::before {
  border-left: none;
  border-bottom: none;
}

.tooltip[data-popper-placement^="right"] > .arrow {
  left: -0.5rem;
}
.tooltip[data-popper-placement^="right"] > .arrow::before {
  border-right: none;
  border-top: none;
}
