@import '@pixelcanvas/ui/dist/variables.scss';

.heading {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 4rem;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1.6rem;

  > :not(:last-child) {
    margin-bottom: 1.2rem;
  }

  @media screen and (min-width: $breakpoint-md) {
    flex-direction: row;
    align-items: center;

    > :first-child {
      margin-right: auto;
    }

    > :not(:first-child) {
      margin-left: 2rem;
    }

    > :not(:last-child) {
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    > :not(:first-child) {
      margin-left: 4rem;
    }
  }
}

.sort {
  width: 24rem;
}

.memberCounter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
  margin-left: 2.4rem;
}

.error {
  color: $red-2;
}
