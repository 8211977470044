@import "../../../../static/styles/variables.scss";

.bubble-stack {
  overflow-y: auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .bubble {
    box-sizing: border-box;
    pointer-events: auto;
    border-radius: 50%;
    margin: 10px;
    height: 60px;
    width: 60px;
    min-height: 60px;
    background: white;
    cursor: pointer;
    position: relative;
  }
}
.focused {
  box-sizing:border-box;
  border: 3px solid $red-2;
}
