@import '@pixelcanvas/ui/dist/variables';

.container {
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  overflow: hidden;
  pointer-events: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgb(0 0 0 / 0.8);

  color: white;
  font-family: "Roboto", Arial, Helvetica, sans-serif;

  .maxwidth {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    max-width: 60rem;

    > * + * {
      margin-top: 6.4rem;
    }
  }

  .text {
    font-size: 3.6rem;
  }

  .inputfield {
    font-size: 2rem;

    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      margin-right: 3.2rem;
    }

    .label {
      white-space: nowrap;
    }

    .checkbox {
      margin-left: 3.2rem;
    }

    .slider {
      margin-left: 3.2rem;
      width: 20rem;
    }
  }

  .smtext {
    margin-top: 1.6rem;
    font-size: 1.6rem;
  }

  .button {
    margin-top: 8rem;
  }
}
