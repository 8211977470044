@import "../../../../../static/styles/variables";

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  .container {
    position: relative;
    width: 70rem;
    background: transparent linear-gradient(109deg, #3D3565 0%, #27204A 20%, #2D2552 75%, #382552 100%) 0% 0% no-repeat padding-box;
    box-sizing: border-box;
    box-shadow: 0 0.3rem 0.6rem #00000029;
    padding: 0;
    color: $purple-5;
    font-weight: 600;
    font-size: 18px;
    border-radius: 5px;
    outline: none;
    .header {
      background: #3F366B;
      border: 1px solid #4D4282;
      padding: 1.9rem 2rem 1.7rem 3rem;
      box-shadow: 0px 3px 6px #0000002F;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .title {
        margin: 0;
        font-size: 2.4rem;
        font-weight: 600;
        color: $gray-15;
      }
      .close-icon {
        margin-left: 2.6rem;
        width: 2.6rem;
        height: 2.6rem;
        cursor: pointer;
        path {
          fill: #A39BC9;
        }
      }
    }
    .main {
      padding: 2.1rem 7rem 7.8rem 7rem;
    }
    .actions {
      display: flex;
      border-top: 1px solid #4E447B;
      padding: 1.6rem 2.3rem 1.7rem 5rem;
      .cancel {
        margin-left: auto;
        font-size: 1.6rem;
      }
      .confirm {
        margin-left: 3.9rem;
        font-size: 1.6rem;
      }
    }
  }
}
