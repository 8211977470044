@import "../../../../static/styles/variables.scss";

.modal {
  min-height: 60%;
  min-width: 60%;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
      display: none;
  }
}

.heading {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;

  .header {
    font-size: 3rem;
    font-weight: bold;
    line-height: 1.3;
    color: $gray-11;
  }

  .search {
    margin-left: auto;
    margin-right: 10rem;
  }
}

.nfts {
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  max-width: 134rem;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
      display: none;
  }
}
