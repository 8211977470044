@import '@pixelcanvas/ui/dist/variables';

.pill {
  box-sizing: border-box;
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;

  font-size: inherit;
}

.span {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $purple-6;
  padding: 0.25em 0.5em;
  border-radius: 2em;
  max-height: 4rem;
}

.picture {
  border-radius: 50%;
  margin-right: 0.8rem;
  width: 2em;
  height: 2em;
  object-fit: cover;
}

.icon {
  width: 1em;
  height: 1em;
  path {
    fill: $high-emphasis;
  }
}

.name {
  color: $high-emphasis;
  margin-right: 0.8rem;
  @media screen and (max-width: 810px) {
    display: none;
  }
}
