.frame {
    width: 100%;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
        Helvetica Neue, sans-serif;
    font-size: 14px;
    border: transparent;
    border-radius: 1rem;
}

.modal {
    display: flex;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
}

.close {
    position: absolute;
    width: 2.1rem;
    height: 3.3rem;
    top: 2.3rem;
    right: 3.2rem;
    z-index: 1;
    cursor: pointer;
    path {
        fill: gray;
    }
}

.modal-content {
    position: relative;
    margin: auto;
    width: 1280px;
    height: 800px;
    outline: none;
    border-radius: 1.5rem;
}
