@import "../../../../../static/styles/variables";

.container {
  flex: 1 1 auto;
  overflow-y: auto;
  .registrations {
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    .row-header {
      .header {
        position: sticky;
        top: 0;
        color: $gray-3;
        background-color: $purple-4;
        padding: 1em;
        border-bottom: 1px solid $purple-21;
        text-align: left;
        font-weight: 600;
      }
      .domain {
        width: auto;
      }
      .date {
        width: 25%;
      }
      .user {
        width: 20%;
      }
      .trash {
        width: 2rem;
      }
    }
    .row-data {
      &:hover {
        background-color: $purple-13 !important;
      }
      .item {
        padding: 1em;
        color: white;
        vertical-align: middle;
      }
      .item-trash {
        vertical-align: middle;
        .delete {
          width: 3.5rem;
          height: 3rem;
          border-radius: 3rem;
          background: transparent;
          border: 0;
          display: flex;
          justify-self: center;
          align-items: center;
          cursor: pointer;
          &:hover {
            background-color: $purple-4;
          }
          .icon {
            width: 2rem;
            height: 2rem;
            display: none;
            padding: 0;
            margin: auto;
            path {
              fill: white;
            }
          }
        }
      }
      &:hover .item-trash .delete .icon {
        display: inline;
      }
      &.odd {
        background-color: $purple-3;
      }
    }
  }

  .empty {
    width: 100%;
    height: 26rem;
    background-color: $purple-22;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .empty-header {
      max-width: fit-content;
      font-size: 1.6rem;
      font-weight: 600;
      color: $purple-5;
    }
    .empty-description {
      max-width: fit-content;
    }
  }
}
