.container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.6rem;
  .input {
    width: 100%;
  }
  .button {
    transform: translateY(0.5rem);
  }
}
