.container {
  flex: 0 0 21%;

  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  width: fit-content;
  height: fit-content;
  border: 0;
  padding: 0;
  margin: 0;
  background: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
}

.image {
  height: 28rem;
  max-height: 28rem;
  object-fit: contain;
}

.name {
  color: white;
  text-align: center;
}

.selectServer {
  position: absolute;
  top: 2.8rem;
  right: 20rem;
  display: none;
}
.container:hover .selectServer {
  display: block;
}

.playNow {
  position: absolute;
  top: 2.8rem;
  left: 20rem;
  display: none;
}
.container:hover .playNow {
  display: block;
}
