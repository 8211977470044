@import "@pixelcanvas/ui/dist/variables";

.modal.modal {
  width: 72rem;
  max-height: 60vh;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.content {
  .button + .button {
    margin-top: 1.6rem;
  }
}
