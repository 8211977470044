@import '../../../static/styles/variables.scss';

.actions {
  pointer-events: auto;
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  color: $gray-2;
}

.button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  &:hover {
    opacity: 0.7;
  }
}

.icon {
  filter: drop-shadow(0 0 1rem rgba(0, 0, 0, 0.5));
}

.overlay.play .button {
  font-size: 6.4rem;
}

.overlay.error .icon {
  font-size: 4.8rem;
}
