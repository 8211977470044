@import '../../styles/variables';

.pageContent {
  background-image: url(../../../../assets/images/on-boarding-bg.jpg);
  background-position: center;
  background-size: cover;
}

.header {
  width: 100%;
}

.progress {
  color: white;
  margin-left: auto;
  padding: 0.5rem 0;
  width: 11.8rem;
  border-radius: 2rem;
  text-align: center;
  background: transparent linear-gradient(106deg, #3d3565 0%, #4f4195 20%, #4f4195 75%, #563583 100%) 0% 0% no-repeat padding-box;
}

.pageContent .mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  user-select: none;
}

.container {
  flex: 1 1 auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 5rem auto;

  overflow-y: hidden;
}

.step {
  color: white;
  height: 100%;

  overflow-y: auto;
  &::-webkit-scrollbar-thumb {
    background-color: #C4C4C4;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  -ms-overflow-style: auto;
  scrollbar-color: #C4C4C4 transparent;
  scrollbar-width: thin;
}

.nav {
  flex: 0 0 4rem;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2rem 2rem;
  border-top: 0.1rem solid #332b57;
  column-gap: 2.4rem;
}
