.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0000001e;
  background: -webkit-radial-gradient(center, #00000027, #ffffff00);
  background: -moz-radial-gradient(center, #00000027, #ffffff00);
  background: radial-gradient(ellipse at center, #00000027, #ffffff00);
  :first-child {
    margin-left: auto;
  }
  .logoContainer {
    .logo {
      max-height: 100px;
      margin-top: 10px;
      margin-right: 20px;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: auto;
    .text {
      margin-top: 1em;
      color: white;
      text-shadow: 0px 0px 5px black;
      font-size: 20px;
    }
    .button {
      margin-top: 1em;
    }
    .icon {
      margin-left: 1rem;
      height: 1.8rem;
      width: 1.8rem;
      path {
        fill: white;
      }
    }
  }
}
