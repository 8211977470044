@import '../../../../static/styles/variables';

.notification-bell {
  position: relative;
  height: 100%;
  width: 100%;

  .svg-logo {
    padding: 10px 0 !important;
    height: auto !important;
    width: 30px !important;
  }

  .badge {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    color: white;
    background: $red-3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}