@import "../../../../../static/styles/variables.scss";

.modal {
  min-height: 60vh;
  min-width: 33vw;
  &::-webkit-scrollbar {
    display: none;
  }
}

.modal-content {
  padding-right: 4rem;
}

.section {
  padding: 20px 0;
  position: relative;
}

.section-label {
  font-size: 2rem;
  font-weight: 600;
  text-transform: capitalize;
}

.title {
  color: $gray-11;
  font-size: 3rem;
  font-weight: 700;
  text-transform: capitalize;
}

.input-label {
  padding: 10px 0;
}

.audio-section {
  border-top: 1px solid $gray-1;
}

.actions-container {
  margin-right: 4rem;
}

.done {
  margin: auto;
}
