@import '../../../../../static/styles/variables.scss';
@import '../../../../../static/styles/mixins.scss';

.modal.modal {
  padding: 0;
  height: 75rem;
  width: 60rem;
  margin: auto;
  @include no-scrollbar;

  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

.content {
  padding: 0;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.heading {
  position: relative;
  min-height: 14rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  background: rgb(255 255 255 / 0.59);
  box-shadow: 0px 3px 6px #0000000f;
  border: 1px solid rgb(255 255 255 / 0.4);
  border-radius: 15px 15px 0px 0px;
}

.title {
  font-size: 3rem;
  line-height: 1.625;
  font-weight: bold;
  margin-top: auto;
  margin-bottom: auto;
  color: $gray-11;
}

.logins {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: auto;
}

.error {
  color: red;
  padding: 0 11.2rem;
  margin-bottom: 3.2rem;
  font-size: 1.4rem;
  font-style: italic;
  text-align: center;
}

.footer {
  margin: 0 auto;
  padding: 0 11.2rem;
  font-style: italic;
  text-align: center;
}
