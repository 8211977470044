@import '../../../static/styles/variables.scss';

$text-color: rgb($gray-26, 0.8);
$button-background-color: rgb(255 255 255 / 0.8);
$button-background-color-hover: white;

.container {
  box-sizing: border-box;
  position: absolute;

  top: 3.2rem;
  left: 3.2rem;

  pointer-events: none;

  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 1.6rem;
  font-weight: 600;

  .button {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    padding: 0;
    border-radius: 2em;
    border: 0;

    pointer-events: auto;
    cursor: pointer;

    background-color: transparent;
  }

  .shadow {
    display: block;
    box-shadow: 0 0 1rem rgb(0, 0, 0, 0.5);
    border-radius: 2em;
    background-color: transparent;
  }

  .span {
    padding: 0.5em 1em;
    border-radius: 2em;

    display: flex;
    flex-direction: row;
    align-items: center;

    color: $text-color;

    background-color: $button-background-color;
    &:hover {
      background-color: $button-background-color-hover;
    }
  }

  .circle {
    min-height: 3em;
    min-width: 3em;
  }

  .icon {
    font-size: 1.5em;
    margin-right: 0.5em;
  }

  .teleportpoints {
    margin-top: 0.8rem;

    display: flex;
    flex-direction: column;

    align-items: flex-start;

    border-radius: 0.25em;

    background-color: $button-background-color;
    box-shadow: 0 0 1rem rgb(0, 0, 0, 0.5);

    padding: 0.25em 0;
  }

  .teleportpoint {
    border: 0;

    pointer-events: auto;
    cursor: pointer;

    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;

    padding: 0.5em 1.25em;

    min-width: 12em;
    width: 100%;

    text-align: left;

    color: $text-color;

    background-color: transparent;
    &:hover {
      background-color: $button-background-color-hover;
    }
  }
}
