@import '../../../../static/styles/variables';

.container {
  position: relative;

  .input {
    width: 100%;
    min-height: 4rem;
    padding: 1rem 2rem;
    outline: none;
    border-radius: 2rem;
    border: 1px solid #B9B9B9B0;
    color: $charcoal;
    font-size: 1.6rem;
  }

  .input::-webkit-search-decoration:hover,
  .input::-webkit-search-cancel-button:hover {
    cursor: pointer;
  }

  .icon {
    width: 2rem;
    position: absolute;
    right: 1.5rem;
    top: 1.3rem;

    path {
      fill: $berry;
    }
  }
}
