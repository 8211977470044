.header {
  .title {
    margin-right: 1.6rem;
  }
  .preview {
    margin-left: auto;
  }
  .publish {
    margin-left: 4rem;
  }
  .user {
    margin-left: 3.2rem;
  }
}
