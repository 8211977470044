@import '@pixelcanvas/ui/dist/variables.scss';

.button {
  box-sizing: border-box;

  font-family: inherit;
  font-size: 1.8rem;
  font-style: inherit;
  font-weight: 400;

  background-color: transparent;
  color: $medium-emphasis;
  border: 0;
  margin: 0;
  padding: 0;

  cursor: pointer;
  text-decoration: none;

  &.selected {
    font-weight: 600;
    color: $high-emphasis;
  }
}
