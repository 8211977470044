@import '@pixelcanvas/ui/dist/variables.scss';
@import '@pixelcanvas/ui/dist/mixins.scss';

.main {
  background-image: url('./background.png');
  background-size: cover;
  background-position: center;
}

.content {
  align-self: center;
}

.withTooltip {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  align-items: center;

  .tooltip {
    margin-left: 0.8rem;
  }
}

.footer {
  align-self: center;
  margin-top: auto;
  width: 100%;
  height: 7.2rem;
  border-top: 1px solid $border-2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.button {
  padding-right: 4rem;
  padding-left: 4rem;
}

.organizations {
  font-size: 1.3rem;
  margin-top: 5rem;
  overflow-y: auto;
  @include thin-scrollbar;

  .organization + .organization {
    margin: 2.4rem 0;
  }

  .organization {
    width: 50rem;
    justify-content: flex-start;
  }

  .add {
    width: 50rem;
    justify-content: center;
    padding: 0;
  }
}

.new.new {
  width: 72rem;
}
